import React, { useCallback, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createGlobalStyle } from 'styled-components';
import ListItemText from '@mui/material/ListItemText';
import SegmentedButton from '../../SegmentedButton';

import {
  BodyForm,
  CloseButton,
  Content,
  DateField,
  Field,
  Footer,
  FormBox,
  Header,
  Label,
  NewTaskContainer,
  SegmentedDiv,
  StyledSaveButton,
} from './styles';
import api from '../../../services/api';
import GuestBox, { GuestUser } from '../../guestsBox';
import { Task } from '../../../pages/TodoPage';

interface User {
  name: string;
  email: string;
}

interface Company {
  companyId: number;
  name: string;
}

interface Usergroup {
  name: string;
  usergroupId: number;
  users: User[];
  companyId: number;
}

interface Props {
  taskToEdit?: Task;
  setTaskToEdit: any;
  getAllTasks?: Function;
  taskList: Task[];
  setTaskList: Function;
}

const GlobalStyle = createGlobalStyle<{ disableOverflow: boolean }>`
  body {
    overflow: ${props => (props.disableOverflow ? 'hidden' : 'unset')};
  }
`;

const TaskBar: React.FC<Props> = ({
  taskToEdit = undefined,
  setTaskToEdit,
  setTaskList,
}) => {
  const [task, setTask] = useState<Task>();
  const [required, setRequired] = useState<any>({});
  const [usergroupsListDB, setUsergroupsListDB] = useState<Usergroup[]>([]);
  const [usergroupsList, setUsergroupsList] = useState<Usergroup[]>([]);
  const [companiesList, setCompaniesList] = useState<Company[]>([]);
  const [hideCompany, setHideCompany] = useState<boolean>(false);
  const [filteredUserList, setFilteredUserList] = useState<User[]>([]);
  const [renderValueCompany, setRenderValueCompany] = useState<string>('');
  const [renderValueAssign, setRenderValueAssign] = useState<string>('');

  const ItemHeight = 48;
  const ItemPaddingTop = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
        width: 250,
      },
    },
  };

  const isEditingTask = taskToEdit?.shortTitle;
  const showTaskBar = !!(taskToEdit && taskToEdit !== undefined);

  /// USERGROUPS
  useEffect(() => {
    api.post(`/usergroups/getByCompanyUser`).then((response: any) => {
      if (response.data) {
        const auxListCompanies = response.data.map((x: any): Company => {
          return { companyId: x.companyId, name: x.companyName ?? x.name };
        });
        const arrayCompanies: Company[] = [
          ...new Map<string, Company>(
            auxListCompanies.map((company: any) => [
              company.companyId,
              company,
            ]),
          ).values(),
        ];

        setCompaniesList(arrayCompanies);
        if (arrayCompanies.length === 1) {
          setTask((prev: any) => {
            return { ...prev, companyId: arrayCompanies[0].companyId };
          });

          setHideCompany(true);
        } else if (arrayCompanies.length > 1) {
          setHideCompany(false);
        } else {
          console.error('The systems can not find any company');
        }

        const userGroupsFromDb = response.data.map((x: any) => {
          return {
            name: x.name,
            usergroupId: x.userGroupId,
            users: x.users,
            companyId: x.companyId,
          };
        });

        setUsergroupsListDB(userGroupsFromDb);

        setUsergroupsList(
          response.data.map((x: any) => {
            return {
              name: x.name,
              usergroupId: x.userGroupId,
              users: x.users,
              companyId: x.companyId,
            };
          }),
        );
      }
    });
  }, []);

  useEffect(() => {
    if (task?.usergroupIds && task?.guests && usergroupsListDB) {
      const { usergroupIds } = task;
      const usersFromSelectedUsergroups = usergroupsListDB
        .filter((x: any) => usergroupIds.includes(x.usergroupId))
        .map((y: any) => y.users)
        .flat();
      const filteredGuests = task?.guests.map((x: any) => {
        return { username: x.name, email: x.email };
      });
      const result = Array.from(
        new Set([...filteredGuests, ...usersFromSelectedUsergroups]),
      );
      const filtered = result.map(x => {
        return {
          name: x.username,
          email: x.email,
        };
      });
      setFilteredUserList(filtered);
      if (task.assign) {
        setRenderValueAssign(task.assign.name);
      }
    }
  }, [task, usergroupsListDB]);

  const handleChange = useCallback(
    <Key extends keyof Task>(prop: Key, value: Task[Key]) => {
      const taskCopy: any = { ...task, guests: task?.guests ?? [] };

      if (prop === 'companyId') {
        taskCopy.assign = { name: '', email: '' };
        taskCopy.usergroupIds = [];
        // filter usergroup by selectedCompany
        setUsergroupsList(
          usergroupsListDB.filter(usergroup => usergroup.companyId === value),
        );
      }

      if (prop === 'assign') {
        setRenderValueAssign(value.name);
      }

      if (prop === 'usergroupIds') {
        if (value.length > 0) {
          const usersFromSelectedUsergroups = usergroupsListDB
            .filter((x: any) => value.includes(x.usergroupId))
            .map((y: any) => y.users)
            .flat();
          const filteredGuests =
            task?.guests.map((x: any) => {
              return { username: x.name, email: x.email };
            }) ?? [];
          const result = Array.from(
            new Set([...filteredGuests, ...usersFromSelectedUsergroups]),
          );
          const filtered = result.map(x => {
            return {
              name: x.username,
              email: x.email,
            };
          });
          setFilteredUserList(filtered);

          // if the current assign is not on the filtered user list, clear
          if (!filtered.find(x => x.email === taskCopy?.assign.email)) {
            taskCopy.assign = { name: '', email: '' };
            setRenderValueAssign('');
          }
        } else {
          taskCopy.assign = { name: '', email: '' };
          setRenderValueAssign('');
        }
      }

      taskCopy[prop] = value;
      setTask(taskCopy);
    },
    [task, usergroupsListDB],
  );

  useEffect(() => {
    if (task?.companyId && companiesList.length > 0) {
      setRenderValueCompany(
        companiesList.find(x => x.companyId === task?.companyId)?.name ?? '',
      );
    } else {
      setRenderValueCompany('');
      if (companiesList.length === 1) {
        setHideCompany(true);
        handleChange('companyId', companiesList[0].companyId);
      }
    }
  }, [companiesList, handleChange, task?.companyId]);

  const handleChangeAssign = useCallback(
    (email: string) => {
      handleChange('assign', {
        name: filteredUserList.find(x => x.email === email)?.name ?? '',
        email,
      });
    },
    [filteredUserList, handleChange],
  );

  const handleChangeEffort = useCallback(
    (value: string) => {
      handleChange('effort', value);
    },
    [handleChange],
  );

  const handleChangeImpact = useCallback(
    (value: string) => {
      handleChange('impact', value);
    },
    [handleChange],
  );

  const handleChangeStatus = useCallback(
    (value: any) => {
      handleChange('status', value);
    },
    [handleChange],
  );

  const handleChangeGuests = useCallback(
    (value: GuestUser[]) => {
      handleChange('guests', value);
    },
    [handleChange],
  );

  const handleChangeCompany = (event: SelectChangeEvent<any>): void => {
    const {
      target: { value },
    } = event;

    handleChange('companyId', value);
  };

  useEffect(() => {
    setRequired({});
    setTask(taskToEdit);
  }, [taskToEdit]);

  const renderSelectedUserGroups = (selected: number[]): any => {
    if (selected.length === 0) return 'Select... ';

    return usergroupsListDB
      .filter(x => selected.includes(x.usergroupId))
      .map(x => x.name)
      .join(', ');
  };

  const closeNewTaskPanel = (): void => {
    setTaskToEdit(undefined);
    setTask(undefined);
    setRenderValueAssign('');
    setHideCompany(false);
  };

  const addTask = (): void => {
    let validation = {};
    if (!task?.shortTitle) {
      validation = { shortTitle: true };
    }

    if (!task?.description) {
      validation = { ...validation, description: true };
    }

    if (!task?.companyId) {
      validation = { ...validation, companyId: true };
    }

    if (!task?.usergroupIds || task?.usergroupIds.length === 0) {
      validation = { ...validation, userGroups: true };
    }

    if (!task?.assign?.email) {
      validation = { ...validation, assign: true };
    }

    if (!task?.effort) {
      validation = { ...validation, effort: true };
    }

    if (!task?.impact) {
      validation = { ...validation, impact: true };
    }

    if (!task?.status) {
      validation = { ...validation, status: true };
    }

    setRequired(validation);
    if (Object.keys(validation).length > 0) {
      console.error('Validation errors:', validation);
      return;
    }

    const newTask = {
      taskId: task?.taskId,
      shortTitle: task?.shortTitle,
      description: task?.description,
      effort: task?.effort,
      impact: task?.impact,
      due: task?.due,
      usergroupIds: task?.usergroupIds,
      status: task?.status,
      goal: task?.goal,
      assign: task?.assign,
      guests: task?.guests?.map((x: any) => {
        return { name: x.name, email: x.email, lastName: x.lastName };
      }),
      companyId: task?.companyId,
    };

    if (isEditingTask) {
      api
        .put('/tasks', newTask)
        .then(response => {
          if (response.status === 200) {
            setTaskList((prev: Task[]) =>
              prev.map((t: Task) =>
                t.taskId === response.data.taskId ? response.data : t,
              ),
            );
            setTaskToEdit(response.data);
            closeNewTaskPanel();
          } else {
            console.error(response);
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      api
        .post('/tasks', newTask)
        .then(response => {
          if (response.status === 200) {
            setTaskList((prev: Task[]) => [...prev, response.data]);
            closeNewTaskPanel();
          } else {
            console.error(response);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  return (
    <>
      {showTaskBar && <GlobalStyle disableOverflow={showTaskBar} />}

      <Drawer anchor="right" open={showTaskBar} onClose={closeNewTaskPanel}>
        <NewTaskContainer id="task-bar">
          <Content id="content">
            <Header id="header">
              <DialogTitle style={{ padding: 0 }}>
                {isEditingTask
                  ? 'Aufgabe bearbeiten'
                  : 'Neue Aufgabe Hinzufügen'}
              </DialogTitle>
              <CloseButton
                title={'Close'}
                variant="contained"
                onClick={() => {
                  setTaskToEdit(undefined);
                }}
              >
                X
              </CloseButton>
            </Header>

            <BodyForm id="body-form">
              <Field
                margin="dense"
                id="taskTitle"
                name="taskTitle"
                value={task?.shortTitle ?? ''}
                onChange={e => handleChange('shortTitle', e.target.value)}
                label="Kurztitel"
                type="text"
                variant="outlined"
                error={!!required.shortTitle}
                helperText={required.shortTitle ? 'required' : ''}
                inputProps={{ maxLength: 20 }}
                // slotProps={{ htmlInput: { maxLength: 12 } }}
              />

              <Field
                margin="dense"
                id="taskDescription"
                name="taskDescription"
                value={task?.description ?? ''}
                onChange={e => handleChange('description', e.target.value)}
                label="Thema"
                type="text"
                variant="outlined"
                error={!!required.description}
                helperText={required.description ? 'required' : ''}
              />

              <Field
                margin="dense"
                id="taskGoal"
                name="taskGoal"
                value={task?.goal ?? ''}
                onChange={e => handleChange('goal', e.target.value)}
                label="Ziel"
                type="text"
                variant="outlined"
              />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DateField
                    label="Fällig"
                    value={dayjs(task?.due || new Date())}
                    onChange={newValue =>
                      handleChange('due', newValue?.toDate() ?? new Date())
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>

              {!hideCompany && (
                <>
                  <FormBox error={!!required.companyId}>
                    <InputLabel id="company-select-label">Company</InputLabel>
                    <Select
                      // disabled={hideCompany}
                      labelId="company-select-label"
                      id="company-select"
                      name="companyList"
                      label="companyList"
                      value={
                        Number(task?.companyId) ? Number(task?.companyId) : ''
                      }
                      onChange={handleChangeCompany}
                      input={<OutlinedInput label="Company" />}
                      renderValue={() => renderValueCompany}
                      MenuProps={MenuProps}
                    >
                      {companiesList?.map((company: Company, index: number) => (
                        <MenuItem key={index} value={Number(company.companyId)}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {required.company ? 'required' : ''}
                    </FormHelperText>
                  </FormBox>
                </>
              )}

              {/* {usergroupsList.length > 0 && ( */}
              <FormBox error={!!required.userGroups}>
                <InputLabel id="usergroup-label">
                  Organisationseinheit
                </InputLabel>
                <Select
                  disabled={task === undefined || task?.companyId === undefined}
                  labelId="usergroup-label"
                  id="usergroup-select"
                  name="usergroupList"
                  multiple
                  value={task?.usergroupIds ?? []}
                  onChange={e =>
                    handleChange('usergroupIds', e.target.value as any)
                  }
                  input={<OutlinedInput label="Organisationseinheit" />}
                  renderValue={renderSelectedUserGroups}
                  MenuProps={MenuProps}
                >
                  {usergroupsList?.map((usergroup: any) => (
                    <MenuItem
                      key={usergroup.usergroupId}
                      value={usergroup.usergroupId}
                    >
                      <Checkbox
                        checked={task?.usergroupIds?.includes(
                          usergroup.usergroupId,
                        )}
                      />
                      <ListItemText primary={usergroup.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {required.usergroups ? 'required' : ''}
                </FormHelperText>
              </FormBox>
              {/* )} */}

              <FormBox error={!!required.assign}>
                <InputLabel id="assign-label">Verantwortlich</InputLabel>
                <Select
                  disabled={
                    task === undefined ||
                    task?.usergroupIds === undefined ||
                    task?.usergroupIds?.length === 0
                  }
                  labelId="assign-label"
                  id="assign-select"
                  name="taskAssign"
                  value={
                    filteredUserList.length > 0 ? task?.assign?.email ?? '' : ''
                  }
                  onChange={e => handleChangeAssign(e.target.value)}
                  input={<OutlinedInput label="Verantwortlich" />}
                  renderValue={() => renderValueAssign}
                  MenuProps={MenuProps}
                >
                  {filteredUserList?.map((userMock: any, index: number) => (
                    <MenuItem key={index} value={userMock.email}>
                      {userMock.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {required.assign ? 'required' : ''}
                </FormHelperText>
              </FormBox>

              <SegmentedDiv>
                <Label htmlFor="taskImpact">Aufwand</Label>
                <SegmentedButton
                  id="taskEffort"
                  options={['Very Low', 'Low', 'High', 'Very High']}
                  value={task?.effort ?? 'Very Low'}
                  onChange={handleChangeEffort}
                />
              </SegmentedDiv>

              <SegmentedDiv>
                <Label htmlFor="taskImpact">Impact</Label>
                <SegmentedButton
                  id="taskImpact"
                  options={['Very Low', 'Low', 'High', 'Very High']}
                  value={task?.impact ?? 'Very Low'}
                  onChange={handleChangeImpact}
                />
              </SegmentedDiv>

              <SegmentedDiv>
                <Label htmlFor="taskStatus">Status</Label>
                <SegmentedButton
                  id="taskStatus"
                  options={['wait', 'in progress', 'done']}
                  renderOptions={[
                    { label: '🕒 Wait', value: 'wait' },
                    { label: '🔄 In progress', value: 'in progress' },
                    { label: '✔️ Done', value: 'done' },
                  ]}
                  value={task?.status ?? 'wait'}
                  onChange={handleChangeStatus}
                />
              </SegmentedDiv>

              <GuestBox
                initialGuests={task?.guests?.map((x: GuestUser) => {
                  return {
                    name: x.name,
                    lastName: x.lastName,
                    email: x.email,
                  };
                })}
                handleChangeGuests={handleChangeGuests}
              />
            </BodyForm>

            <Footer id="footer">
              <StyledSaveButton onClick={addTask}>OK</StyledSaveButton>
            </Footer>
          </Content>
        </NewTaskContainer>
      </Drawer>
    </>
  );
};

export default TaskBar;
