import * as React from 'react';

import { useEffect, useState } from 'react';
import api from '../../../services/api';
import EisenhowerMatrix from '../../../components/eisenhowerMatrix';
import { TabPanel } from './styles';
import { Task } from '..';

interface Props {
  chosenTab: number;
  taskList: Task[];
  setTaskList: Function;
}

const EisenhowerTab: React.FC<Props> = ({
  chosenTab,
  taskList,
  setTaskList,
}) => {
  const [tasksPerQuadrant, setTasksPerQuadrant] = useState<any>([]);

  useEffect(() => {
    api.get('/tasks/getEisenhowerData').then(response => {
      setTasksPerQuadrant(response.data);
    });
  }, [taskList]);

  return (
    <TabPanel hidden={chosenTab !== 1}>
      <EisenhowerMatrix
        taskList={taskList}
        setTaskList={setTaskList}
        tasksPerQuadrant={tasksPerQuadrant}
      />
    </TabPanel>
  );
};

export default EisenhowerTab;
