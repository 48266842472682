import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useCallback, useEffect, useState } from 'react';
import { Permission } from '../../../pages/AccessControlPage/roleTab';
import {
  Methods,
  Resource,
} from '../../../pages/AccessControlPage/resourcesTab';

interface props {
  resource?: Resource;
  setOpen: any;
  handleCloseOptions?: any;
  permissions: Permission[];
  handleAdd?: any;
  handleSaveEdit?: any;
  handleClose: any;
}

const applyStyle = (selected: boolean, method: string): any => {
  let style: any = { borderRadius: '15px' };
  if (selected) {
    if (method === 'GET') {
      style = {
        background: '#99c0ff',
        borderColor: '#99c0ff',
        color: 'black',
        borderRadius: '15px',
      };
    }

    if (method === 'POST') {
      style = {
        background: '#c7febc',
        borderColor: '#c7febc',
        color: 'black',
        borderRadius: '15px',
      };
    }

    if (method === 'PUT') {
      style = {
        background: '#e6ca8f',
        borderColor: '#e6ca8f',
        color: 'black',
        borderRadius: '15px',
      };
    }

    if (method === 'DELETE') {
      style = {
        background: '#e68f8f',
        borderColor: '#e68f8f',
        color: 'black',
        borderRadius: '15px',
      };
    }
  }

  return {
    id: `simple-tab-${selected}`,
    style,
  };
};

const ResourceContent: React.FC<props> = ({
  resource = undefined,
  setOpen,
  handleCloseOptions = undefined,
  permissions,
  handleAdd = undefined,
  handleSaveEdit = undefined,
  handleClose,
}) => {
  const [inputRoute, setInputRoute] = useState<string>('');

  const [routeTypeSelected, setRouteTypeSelected] = useState<any>({
    GET: false,
    POST: false,
    PUT: false,
    DELETE: false,
  });

  const [newRoutesObj, setNewRoutesObj] = useState<Methods>({
    GET: [],
    POST: [],
    PUT: [],
    DELETE: [],
  });

  const [resourceNameForm, setResourceNameForm] = useState<string>('');
  const [required, setRequired] = useState<any>({});

  useEffect(() => {
    if (resource) {
      setResourceNameForm(resource.resourceName);
      setNewRoutesObj(resource.methods);
    }
  }, [permissions, resource]);

  const addRoute = (): void => {
    let selectedRouteType = '';
    Object.entries(routeTypeSelected).forEach(([key, value]) => {
      if (value) {
        selectedRouteType = key;
      }
    });

    newRoutesObj[selectedRouteType as keyof Methods].push(inputRoute);

    setInputRoute('');
  };

  const hasRequiredFields = useCallback((formResource: any): boolean => {
    const requiredObj: any = {};
    if (formResource.resourceName === '') {
      requiredObj.resourceName = true;
    }

    if (formResource.methods.length === 0) {
      requiredObj.methods = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const formResource: any = {
        resourceName: formdata?.get('resourceName')?.toString() ?? '',
        methods: newRoutesObj,
      };

      if (!hasRequiredFields(formResource)) {
        setOpen(false);
        if (resource) {
          handleCloseOptions();
          handleSaveEdit({ ...formResource, resourceId: resource.resourceId });
        } else {
          handleAdd(formResource);
          setResourceNameForm('');
        }
      }
    },
    [
      handleAdd,
      handleCloseOptions,
      handleSaveEdit,
      hasRequiredFields,
      newRoutesObj,
      resource,
      setOpen,
    ],
  );

  const handleCancel = (): void => {
    setResourceNameForm('');
    setOpen(false);
    if (resource) {
      handleCloseOptions();
    }
    handleClose();
  };

  const handleSelectRouteType = (routeType: string): void => {
    const newObj: any = {};
    Object.keys(routeTypeSelected).forEach((rType: any) => {
      if (rType === routeType) {
        newObj[rType] = true;
      } else {
        newObj[rType] = false;
      }
    });

    setRouteTypeSelected(newObj);
  };

  const handleDeleteChip = (type: string, route: any): void => {
    const newObj = {
      GET: newRoutesObj.GET,
      POST: newRoutesObj.POST,
      PUT: newRoutesObj.PUT,
      DELETE: newRoutesObj.DELETE,
    };

    const index = newObj[type as keyof Methods].indexOf(route);
    newObj[type as keyof Methods].splice(index, 1);

    setNewRoutesObj(newObj);
  };

  const htmlRoutesList = Object.keys(newRoutesObj).map((type: any) => {
    return newRoutesObj[type as keyof Methods].map(
      (route: string, index: number) => {
        return (
          <div key={index} style={{ margin: '3px' }}>
            <Chip
              avatar={
                <Avatar
                  style={{
                    width: '37px',
                    height: '27px',
                    fontSize: '8px',
                    background:
                      type === 'GET'
                        ? '#99c0ff'
                        : type === 'POST'
                          ? '#c7febc'
                          : type === 'PUT'
                            ? '#e6ca8f'
                            : type === 'DELETE'
                              ? '#e68f8f'
                              : 'darkgray',
                    color: 'black',
                  }}
                >
                  {type}
                </Avatar>
              }
              label={route}
              variant="filled"
              size="medium"
              style={{ fontStyle: 'italic' }}
              onDelete={() => handleDeleteChip(type, route)}
            />
          </div>
        );
      },
    );
  });

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <DialogTitle>
        {resource ? 'Edit resource' : 'Create a new resource'}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          margin="dense"
          id="resourceName"
          name="resourceName"
          value={resourceNameForm}
          onChange={event => setResourceNameForm(event.target.value)}
          label="Resource Name"
          type="text"
          variant="outlined"
          error={!!required.resourceName}
          helperText={required.resourceName ? 'required' : ''}
          style={{ marginBottom: '20px' }}
        />
        <h4 style={{ marginTop: 0, marginBottom: '10px' }}>Add new routes:</h4>
        <Paper elevation={3} style={{ background: '#ecebeb' }}>
          <h5
            style={{
              margin: 0,
              fontWeight: '100',
              marginLeft: '10px',
              marginTop: '10px',
            }}
          >
            Select the route type:
          </h5>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              padding: '13px',
            }}
          >
            <Button
              {...applyStyle(routeTypeSelected.GET, 'GET')}
              onClick={() => handleSelectRouteType('GET')}
              variant="outlined"
            >
              GET
            </Button>
            <Button
              {...applyStyle(routeTypeSelected.POST, 'POST')}
              onClick={() => handleSelectRouteType('POST')}
              variant="outlined"
            >
              POST
            </Button>
            <Button
              {...applyStyle(routeTypeSelected.PUT, 'PUT')}
              onClick={() => handleSelectRouteType('PUT')}
              variant="outlined"
            >
              PUT
            </Button>
            <Button
              {...applyStyle(routeTypeSelected.DELETE, 'DELETE')}
              onClick={() => handleSelectRouteType('DELETE')}
              variant="outlined"
            >
              DELETE
            </Button>
          </DialogContent>
          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <TextField
              margin="dense"
              id="route"
              name="route"
              value={inputRoute}
              onChange={event => setInputRoute(event.target.value)}
              label="Route"
              type="text"
              variant="outlined"
              error={!!required.username}
              helperText={required.username ? 'required' : ''}
              style={{ width: '94%', alignSelf: 'center' }}
            />
          </DialogContent>

          <DialogContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '10px',
              paddingTop: '0px',
              gap: '15px',
            }}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={addRoute}>
              Add Route
            </Button>
          </DialogContent>
        </Paper>
        <h4 style={{ marginBottom: '10px' }}>Routes list:</h4>
        <Paper
          style={{
            background: '#ecebeb',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2%',
            padding: '10px',
          }}
        >
          {htmlRoutesList}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit">{resource ? 'Save' : 'Create'}</Button>
      </DialogActions>
    </Box>
  );
};

export default ResourceContent;
