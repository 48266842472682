import React, { useState } from 'react';
import ThreeSixtyRoundedIcon from '@mui/icons-material/ThreeSixtyRounded';
import {
  CardBackSide,
  CardFrontSide,
  Container,
  Description,
  IconDiv,
  QuadrantBox,
  Text,
  Title,
} from './styles';
import SubQuadrant from '../subQuadrant';

interface Props {
  info: any;
}

const Quadrant: React.FC<Props> = ({ info }) => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const handleFlip = (): any => {
    setIsFlipped(!isFlipped);
  };

  return (
    <QuadrantBox id="quadrant-box" name={info.name}>
      <Container
        isFlipped={isFlipped}
        onClick={handleFlip}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <CardFrontSide name={info.name}>
          <Title>
            {info.icon}
            <h2 style={{ marginTop: '15%' }}>{info.name}</h2>
          </Title>
          <Description>
            <Text>{info.description}</Text>
          </Description>
          <IconDiv>{showIcon && <ThreeSixtyRoundedIcon />}</IconDiv>
        </CardFrontSide>
        <CardBackSide>
          <SubQuadrant
            name="TopLeft"
            color={info.backQuadrantsColor}
            data={info.subQuadData ? info.subQuadData.topLeft : []}
            setTaskToEdit={info.setTask}
          />
          <SubQuadrant
            name="TopRight"
            color={info.backQuadrantsColor}
            data={info.subQuadData ? info.subQuadData.topRight : []}
            setTaskToEdit={info.setTask}
          />
          <SubQuadrant
            name="BottomLeft"
            color={info.backQuadrantsColor}
            data={info.subQuadData ? info.subQuadData.bottomLeft : []}
            setTaskToEdit={info.setTask}
          />
          <SubQuadrant
            name="BottomRight"
            color={info.backQuadrantsColor}
            data={info.subQuadData ? info.subQuadData.bottomRight : []}
            setTaskToEdit={info.setTask}
          />
        </CardBackSide>
      </Container>
    </QuadrantBox>
  );
};

export default Quadrant;
