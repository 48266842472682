import styled from 'styled-components';

export const Container = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 20vw;

  @media (min-width: 800px) and (max-width: 1200px) {
    width: 63%;
    height: 83%;
    margin-left: 16vw;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    width: 73%;
    height: 64%;
    margin-left: 14vw;
  }

  @media (max-width: 500px) {
    width: 72%;
    height: 41%;
    margin-left: 13vw;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100%;
  justify-items: center;
  position: relative;
  background: transparent;
`;

export const Graph = styled.div`
  display: flex;
  flex-direction: row;
  height: 92%;
  width: 100%;
`;

export const GridContainer = styled.div`
  height: 100%;
  width: 100%;
`;
