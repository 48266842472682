import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';
import { Widget } from '../pages/UserGroupsPage';

interface User {
  id: string;
  name: string;
  email: string;
  urlLogo: string;
  mainColor: string;
  companyId: number;
  userGroupName: string;
  roles: any[];
  dashboards: number[];
  videoUrl: string;
  widgets: Widget[];
  isGuest: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<any>;
  signOut(): void;
  getUserGroups(user: User): Promise<User>;
}

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Victor-insights:token');
    const user = localStorage.getItem('@Victor-insights:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@Victor-insights:token');
    localStorage.removeItem('@Victor-insights:user');

    setData({} as AuthState);
  }, []);

  const getUserGroups = useCallback(
    async (user: User) => {
      let roles: any[] = [];
      let uniqueWidgets: Widget[] = [];
      let dashboards: number[] = [];
      let widgets: any[] = [];
      let urlVideo = '';

      const userWithData: User = user;

      let isGuest = false;
      await api
        .get(`usergroups/email/${user.email}`)
        .then(response => {
          if (response.data.isGuest === true) {
            isGuest = true;
            roles = response.data.guestRole;
          } else {
            response.data?.forEach((userGroup: any) => {
              if (userGroup.dashboards) {
                dashboards = [
                  ...new Set([...dashboards, ...userGroup.dashboards]),
                ]; // concat without duplicates
              }

              if (userGroup.roles) {
                roles = [...new Set([...roles, ...userGroup.roles])]; // concat without duplicates
              }

              if (userGroup?.settings?.widgets) {
                widgets = [
                  ...widgets,
                  ...Array.from(
                    new Set(
                      userGroup?.settings?.widgets.map((obj: any) => obj),
                    ),
                  ),
                ];
              }

              if (userGroup?.settings?.videoLink) {
                urlVideo = userGroup?.settings?.videoLink;
              }
            });

            uniqueWidgets = widgets.filter(
              (widget, i, a) =>
                a.findIndex(w => w.widgetName === widget.widgetName) === i,
            );
          }
        })
        .catch(err => {
          console.log(err);
          signOut();
        });

      userWithData.roles = roles;
      userWithData.dashboards = dashboards;
      userWithData.widgets = uniqueWidgets;
      userWithData.videoUrl = urlVideo;
      userWithData.isGuest = isGuest;

      return userWithData;
    },
    [signOut],
  );

  const signIn = useCallback(
    async ({ email, password }: any) => {
      try {
        const response = await api.post('sessions', {
          email,
          password,
        });
        const { token, user } = response.data;

        if (token && user) {
          localStorage.setItem('@Victor-insights:token', token);
          localStorage.setItem('@Victor-insights:user', JSON.stringify(user));

          api.defaults.headers.authorization = `Bearer ${token}`;
        }

        const userWithData: User = await getUserGroups(user);
        setData({ token, user: userWithData });

        if (userWithData.isGuest) {
          return { signIn: true, isGuest: true };
        }

        return { signIn: true, isGuest: false };
      } catch (error) {
        return { signIn: false, isGuest: false };
      }
    },
    [getUserGroups],
  );

  useEffect(() => {
    api.interceptors.response.use(
      (response: any) => response,
      (err: any) => {
        if (err.response.status === 401) {
          // console.error('Unauthorized');
          signOut();
        }

        return Promise.reject(err);
      },
    );
  }, [signOut]);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, getUserGroups }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
