import styled from 'styled-components';

interface Props {
  name: string;
}

const getPadding = (name: string): string => {
  switch (name) {
    case 'low-impact':
      return 'padding-right: 1vw; @media (min-width: 800px) and (max-width: 1200px) {padding-right: 2vw;} @media (min-width: 500px) and (max-width: 800px) {padding-right: 3vw;} @media (max-width: 500px) {padding-right: 3vw;}';
    case 'high-impact':
      return 'padding-left: 1vw; @media (min-width: 800px) and (max-width: 1200px) {padding-left: 2vw;} @media (min-width: 500px) and (max-width: 800px) {padding-left: 3vw;} @media (max-width: 500px) {padding-left: 3vw;}';
    default:
      return 'padding: 1vw;';
  }
};

export const Container = styled.div<Props>`
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ name }) => getPadding(name)};
`;

export const ImpactLabel = styled.span`
  color: white;
  text-align: center;
  font-size: 0.9vw;

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 1.5vw;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    font-size: 1.8vw;
  }

  @media (max-width: 500px) {
    font-size: 2vw;
  }
`;
