import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../modal';
import AlertDialogSlide from '../../alertDialog';
import { Permission } from '../../../pages/AccessControlPage/permissionTab';
import { Resource } from '../../../pages/AccessControlPage/resourcesTab';
import { Header } from './styles';

interface props {
  permission: Permission;
  handleDelete: any;
  handleSaveEdit: any;
  resourcesList: Resource[];
}

const PermissionCard: React.FC<props> = ({
  permission,
  handleDelete,
  handleSaveEdit,
  resourcesList,
}) => {
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const handleCloseOptions = (): void => {
    setAnchorOptions(null);
  };

  const handleClickDelete = (): void => {
    setOpenAlertDialog(true);
  };

  const handleDeletion = (): void => {
    handleDelete({ permission, handleCloseOptions });
    setOpenAlertDialog(false);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const openModalEdit = (): void => {
    setOpen(true);
  };

  return (
    <>
      {openAlertDialog && (
        <AlertDialogSlide
          title={'Delete Permission'}
          msg={'Are you sure you want to delete this Permission?'}
          handleYes={handleDeletion}
          setShow={setOpenAlertDialog}
        />
      )}

      <Card
        id="card"
        sx={{
          background: '#ECF3FB',
          marginRight: '30px',
          minWidth: '300px',
          borderRadius: '20px',
        }}
      >
        <Header
          title={permission.name}
          style={{ paddingBottom: '0px' }}
          action={
            <>
              <IconButton onClick={handleOptions} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
              <Menu
                sx={{ mt: '35px', left: '10px' }}
                id="menu-appbar"
                anchorEl={anchorOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorOptions)}
                onClose={handleCloseOptions}
              >
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalEdit}
                >
                  <EditIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={() => handleClickDelete()}
                >
                  <DeleteForeverIcon style={{ top: '10px' }} />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '2px',
                    }}
                    textAlign="center"
                  >
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          }
        />

        <CardActions
          style={{
            paddingLeft: '26px',
            fontSize: 'small',
            paddingBottom: '20px',
          }}
        >
          {permission.description}
        </CardActions>
        <Modal
          content={'permission'}
          permissionProp={{
            open,
            setOpen,
            handleSaveEdit,
            permissionEdit: permission,
            handleCloseOptions,
            resourcesList,
          }}
        ></Modal>
      </Card>
    </>
  );
};

export default PermissionCard;
