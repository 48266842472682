import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Grid, Link } from '@mui/material';
import { useAuth } from '../../context/authContext';
import api from '../../services/api';
import { useLogger } from '../../context/logContext';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const { logger } = useLogger();
  const navigate = useNavigate();
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [messageFirstPwd, setMessageFirstPwd] = useState<string>('');
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);
  const [helperTextPassword, setHelperTextPassword] = useState<string>('');

  const checkPassword = useCallback(async (email: string) => {
    const response = await api.post('/users/checkPassword', {
      email,
      isReset: false,
    });
    return response.data;
  }, []);

  const validateEmail = useCallback(
    (email: string): boolean => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        logger('error', 'email logging', `invalid format to: "${email}"`);
        return true; // is not valid
      }
      return false; // is valid
    },
    [logger],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const user: SignInFormData = {
        email: data?.get('email')?.toString().toLowerCase() ?? '',
        password: data?.get('password')?.toString() ?? '',
      };

      const isEmailInvalid = validateEmail(user.email);
      if (!isEmailInvalid) {
        if (showPwd) {
          const logged = await signIn(user);
          if (logged.signIn) {
            navigate(logged.isGuest ? '/tasks' : '/');
          } else {
            setErrorPassword(true);
            setHelperTextPassword('Incorrect email/password combination');
          }
        } else {
          const hasPassword = await checkPassword(user.email);
          if (hasPassword.status) {
            setShowPwd(true);
          } else {
            setMessageFirstPwd(hasPassword.message);
          }
        }
      } else {
        setErrorEmail(true);
      }
    },
    [checkPassword, navigate, showPwd, signIn, validateEmail],
  );

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}></Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ width: '100%', mt: 2 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              error={errorEmail}
              helperText={errorEmail ? 'Invalid email format' : ''}
              autoComplete="email"
              autoFocus
            />

            {showPwd && (
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                error={errorPassword}
                helperText={helperTextPassword}
                type="password"
                id="password"
                autoComplete="current-password"
              />
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              // onClick={handleSubmit}
            >
              {showPwd ? 'Sign in' : 'Next'}
            </Button>
          </Box>
          {messageFirstPwd !== '' && (
            <h4 style={{ color: '#4D50FF' }}>{messageFirstPwd}</h4>
          )}
        </Box>
        <Grid container>
          <Grid item xs>
            <Link href="/forgotPassword" variant="body2">
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default SignIn;
