import React, { useEffect, useState } from 'react';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import Quadrant from '..';

interface Props {
  data: any;
  setTask: any;
}

const DoitQuadrant: React.FC<Props> = ({ data, setTask }) => {
  const [doitInfo, setDoitInfo] = useState<any>({});

  useEffect(() => {
    setDoitInfo({
      subQuadData: {
        topLeft: data ? data['e(vLow)i(high)'] : [],
        topRight: data ? data['e(vLow)i(vHigh)'] : [],
        bottomLeft: data ? data['e(low)i(high)'] : [],
        bottomRight: data ? data['e(low)i(vHigh)'] : [],
      },
      name: 'ZU ERLEDIGEN',
      description:
        'Diese Aufgaben haben die höchste Priorität, da sie bei minimalem Aufwand erhebliche Resultate und Vorteile bringen. Sie sollten zuerst in Angriff genommen werden.',
      backQuadrantsColor: 'rgba(140, 234, 176, 0.80)',
      icon: (
        <StarRoundedIcon
          sx={{
            width: '11%',
            height: '40%',
            marginTop: '10%',
            marginRight: '1%',
          }}
        />
      ),
      setTask,
    });
  }, [data, setTask]);

  return <Quadrant info={doitInfo}></Quadrant>;
};

export default DoitQuadrant;
