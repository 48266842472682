import { SegmentButton, SegmentedControl } from './styles';

interface RenderOption {
  label: string;
  value: string;
}

const SegmentedButton = ({
  options,
  value,
  onChange,
  renderOptions,
}: any): any => {
  return (
    <SegmentedControl>
      {options.map((option: string) => (
        <SegmentButton
          key={option}
          onClick={() => onChange(option)}
          $active={value === option}
        >
          {renderOptions
            ? renderOptions.find((x: RenderOption) => x.value === option)
                .label ?? ''
            : option}
        </SegmentButton>
      ))}
    </SegmentedControl>
  );
};

export default SegmentedButton;
