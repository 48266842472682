import styled from 'styled-components';

interface Props {
  name: string;
}

const getPadding = (name: string): string => {
  switch (name) {
    case 'low-effort':
      return 'padding-bottom: 0.3vw; @media (min-width: 800px) and (max-width: 1200px) {padding-bottom: 1vw;} @media (min-width: 500px) and (max-width: 800px) {padding-bottom: 1vw;} @media (max-width: 500px) {padding-bottom: 2vw;}';
    case 'high-effort':
      return 'padding-top: 0.3vw; @media (min-width: 800px) and (max-width: 1200px) {padding-top: 0.7vw;} @media (min-width: 500px) and (max-width: 800px) {padding-top: 1vw;} @media (max-width: 500px) {padding-top: 1vw;}';
    default:
      return 'padding: 1vw;';
  }
};

export const Container = styled.div<Props>`
  width: 100%;
  height: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ name }) => getPadding(name)};
`;

export const EffortLabel = styled.span`
  color: white;
  text-align: center;
  font-size: 0.9vw;

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 1.5vw;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    font-size: 1.8vw;
  }

  @media (max-width: 500px) {
    font-size: 2vw;
  }
`;
