import React, { useCallback, useState } from 'react';
import {
  AddButton,
  Container,
  Content,
  GuestCircle,
  ModalGuestContent,
  OverlayModalGuest,
  RemoveIcon,
  Title,
  TooltipGuest,
} from './styles';
import AddGuestBox from './addGuestModal';

export interface GuestUser {
  name: string;
  email: string;
  lastName: string;
}

interface GuestProps {
  initialGuests: GuestUser[] | undefined;
  handleChangeGuests: (guests: GuestUser[]) => void;
}

const GuestBox: React.FC<GuestProps> = ({
  initialGuests,
  handleChangeGuests,
}) => {
  // const [guests, setGuests] = useState<GuestUser[]>(initialGuests ?? []);
  const [guestsToDelete, setGuestsToDelete] = useState<string[]>([]);
  const [showModalGuests, setShowModalGuests] = useState(false);
  const [mouseHoverGuest, setMouseHoverGuest] = useState<string | null>(null);

  const handleRemoveGuest = (email: string): void => {
    handleChangeGuests(
      initialGuests?.filter(guest => guest.email !== email) ?? [],
    );
  };

  const handleGuests = useCallback(
    (value: GuestUser[]) => {
      handleChangeGuests(value);
    },
    [handleChangeGuests],
  );

  return (
    <>
      <Container id="guest-container">
        <Title>Gäste</Title>

        {initialGuests?.map(guest => (
          <Content
            id="content-container"
            key={guest.email}
            onMouseEnter={() => setMouseHoverGuest(guest.email)}
            onMouseLeave={() => setMouseHoverGuest(null)}
          >
            <GuestCircle id="guest-circle">
              <span
                style={{ marginBottom: '0.2vh' }}
              >{`${guest.name[0] + guest.lastName[0]}`}</span>
            </GuestCircle>

            {mouseHoverGuest === guest.email && (
              <RemoveIcon onClick={() => handleRemoveGuest(guest.email)}>
                <span style={{ paddingBottom: '0.5vh' }}>×</span>
              </RemoveIcon>
            )}

            {/* Tooltip */}
            {mouseHoverGuest === guest.email && (
              <TooltipGuest>
                <strong>{`${guest.name} ${guest.lastName}`}</strong>
                <br />
                {guest.email}
              </TooltipGuest>
            )}
          </Content>
        ))}
        <AddButton onClick={() => setShowModalGuests(true)}>+</AddButton>
      </Container>
      {showModalGuests && (
        <OverlayModalGuest
          onClick={(e: any) => {
            e.stopPropagation();
            setShowModalGuests(false);
          }}
        >
          <ModalGuestContent
            onClick={(e: any) => {
              e.stopPropagation();
            }}
          >
            <AddGuestBox
              usersList={initialGuests ?? []}
              setUsersList={handleGuests}
              usersToDelete={guestsToDelete}
              setUsersToDelete={setGuestsToDelete}
              isEdit={false} // TODO:ARRUMAR ISSO
              setClose={setShowModalGuests}
            ></AddGuestBox>
          </ModalGuestContent>
        </OverlayModalGuest>
      )}
    </>
  );
};

export default GuestBox;
