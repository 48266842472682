import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useCallback, useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import { Permission } from '../../../pages/AccessControlPage/roleTab';
import { Resource } from '../../../pages/AccessControlPage/resourcesTab';
import { PermissionResource } from '../../../pages/AccessControlPage/permissionTab';
import { RouteText } from './styles';

interface props {
  permission?: Permission;
  setOpen: any;
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  handleClose: any;
  resourcesList: Resource[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props1: TabPanelProps): any => {
  const { children, value, index, ...other } = props1;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          id="asda"
          sx={{
            padding: '8px',
            background: '#e9e9e9',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number): any => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: { fontSize: 'x-small' },
  };
};

const PermissionContent: React.FC<props> = ({
  permission = undefined,
  setOpen,
  handleCloseOptions = undefined,
  handleAdd = undefined,
  handleSaveEdit = undefined,
  handleClose,
  resourcesList,
}) => {
  const [permissionNameForm, setPermissionNameForm] = useState<string>('');
  const [permissionDescriptionForm, setPermissionDescriptionForm] =
    useState<string>('');

  const [choosedTab, setChoosedTab] = useState<number>(0);
  const [required, setRequired] = useState<any>({});
  const [choosedResourcesList, setChoosedResourcesList] = useState<any>([]);

  const [resources, setResources] = useState<any>(resourcesList);

  const handleCheck = (resource: any, type: string, route: any): void => {
    const permissionResource: PermissionResource = {
      resourceName: resource.resourceName,
      resourceType: type,
      resourceRoute: route,
    };

    const getResourceInList = choosedResourcesList.filter(
      (res: PermissionResource) =>
        res.resourceName === permissionResource.resourceName &&
        res.resourceType === permissionResource.resourceType &&
        res.resourceRoute === permissionResource.resourceRoute,
    )[0];

    if (getResourceInList) {
      const index = choosedResourcesList.indexOf(getResourceInList);
      choosedResourcesList.splice(index, 1);
    } else {
      choosedResourcesList.push(permissionResource);
    }

    setChoosedResourcesList([...choosedResourcesList]);
  };

  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: number,
  ): any => {
    setChoosedTab(newValue);
  };

  useEffect(() => {
    if (permission) {
      const resourcesFromEdit: any = [...resourcesList];

      // here is necessary check if the routes really exist because in some moment,
      // someone can delete or change some route, and this change should be detected here

      // TODO: detetec if has routes to remove and call backend to save if has
      const checkedPermissions: any = [];
      permission.resources.forEach((permRes: any) => {
        const res = resourcesFromEdit.filter(
          (r: any) => r.resourceName === permRes.resourceName,
        )[0];

        if (res.methods[permRes.resourceType].includes(permRes.resourceRoute)) {
          checkedPermissions.push(permRes);
        }
      });

      setChoosedResourcesList(checkedPermissions);
      setResources(resourcesFromEdit);

      setPermissionNameForm(permission.name);
      setPermissionDescriptionForm(permission.description);
    }
  }, [permission, resourcesList]);

  const hasRequiredFields = useCallback((formPermission: any): boolean => {
    const requiredObj: any = {};
    if (formPermission.name === '') {
      requiredObj.roleName = true;
    }

    if (formPermission.resourcesList.length === 0) {
      requiredObj.resourcesList = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const formPermission: any = {
        name: formdata?.get('permissionName')?.toString() ?? '',
        description: formdata?.get('description')?.toString() ?? '',
        resourcesList: choosedResourcesList,
      };

      if (!hasRequiredFields(formPermission)) {
        setOpen(false);
        if (permission) {
          handleCloseOptions();
          handleSaveEdit({
            ...formPermission,
            permissionId: permission.permissionId,
          });
        } else {
          handleAdd(formPermission);
          setPermissionNameForm('');
        }
      }
    },
    [
      handleAdd,
      handleCloseOptions,
      handleSaveEdit,
      hasRequiredFields,
      permission,
      choosedResourcesList,
      setOpen,
    ],
  );

  const handleCancel = (): void => {
    setPermissionNameForm('');
    setOpen(false);
    if (permission) {
      handleCloseOptions();
    }
    handleClose();
  };

  const choosedResources = choosedResourcesList.map((res: any, index: any) => {
    if (res.resourceType === 'GET') {
      return (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '7px',
          }}
        >
          <Chip
            label="GET"
            variant="outlined"
            size="small"
            style={{ background: '#99c0ff', fontSize: 'x-small' }}
          />
          <RouteText>{res.resourceRoute}</RouteText>
        </div>
      );
    }

    if (res.resourceType === 'POST') {
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label="POST"
            variant="outlined"
            size="small"
            style={{ background: '#c7febc', fontSize: 'x-small' }}
          />
          <RouteText>{res.resourceRoute}</RouteText>
        </div>
      );
    }

    if (res.resourceType === 'PUT') {
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label="PUT"
            variant="outlined"
            size="small"
            style={{ background: '#e6ca8f', fontSize: 'x-small' }}
          />
          <RouteText>{res.resourceRoute}</RouteText>
        </div>
      );
    }

    if (res.resourceType === 'DELETE') {
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label="DELETE"
            variant="outlined"
            size="small"
            style={{ background: '#e68f8f', fontSize: 'x-small' }}
          />
          <RouteText>{res.resourceRoute}</RouteText>
        </div>
      );
    }

    return '';
  });

  const resourcesHTML = resources?.map((sp: any, index: any) => (
    <CustomTabPanel key={index} value={choosedTab} index={index}>
      <Paper elevation={2} style={{ padding: '5px' }}>
        {sp.methods.GET.map((route: any, index1: any) => (
          <div key={index1} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={choosedResourcesList.some(
                (cr: any) =>
                  cr.resourceRoute === route && cr.resourceType === 'GET',
              )}
              onChange={() => handleCheck(sp, 'GET', route)}
              size="small"
            />
            <Chip
              label="GET"
              variant="outlined"
              size="small"
              style={{ background: '#99c0ff', fontSize: 'x-small' }}
            />
            <RouteText>{route}</RouteText>
          </div>
        ))}
        {sp.methods.POST.map((route: any, index1: any) => (
          <div key={index1} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={choosedResourcesList.some(
                (cr: any) =>
                  cr.resourceRoute === route && cr.resourceType === 'POST',
              )}
              onChange={() => handleCheck(sp, 'POST', route)}
              size="small"
            />
            <Chip
              label="POST"
              variant="outlined"
              size="small"
              style={{ background: '#c7febc', fontSize: 'x-small' }}
            />
            <RouteText>{route}</RouteText>
          </div>
        ))}
        {sp.methods.PUT.map((route: any, index1: any) => (
          <div key={index1} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={choosedResourcesList.some(
                (cr: any) =>
                  cr.resourceRoute === route && cr.resourceType === 'PUT',
              )}
              onChange={() => handleCheck(sp, 'PUT', route)}
              size="small"
            />
            <Chip
              label="PUT"
              variant="outlined"
              size="small"
              style={{ background: '#e6ca8f', fontSize: 'x-small' }}
            />
            <RouteText>{route}</RouteText>
          </div>
        ))}
        {sp.methods.DELETE.map((route: any, index1: any) => (
          <div key={index1} style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={choosedResourcesList.some(
                (cr: any) =>
                  cr.resourceRoute === route && cr.resourceType === 'DELETE',
              )}
              onChange={() => handleCheck(sp, 'DELETE', route)}
              size="small"
            />
            <Chip
              label="DELETE"
              variant="outlined"
              size="small"
              style={{ background: '#e68f8f', fontSize: 'x-small' }}
            />
            <RouteText>{route}</RouteText>
          </div>
        ))}
      </Paper>
    </CustomTabPanel>
  ));

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <DialogTitle>
        {permission ? 'Edit permission' : 'Create a new permission'}
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          padding: '3px 25px',
        }}
      >
        <TextField
          margin="dense"
          id="permissionName"
          name="permissionName"
          value={permissionNameForm}
          onChange={event => setPermissionNameForm(event.target.value)}
          label="Permission Name"
          type="text"
          variant="outlined"
          error={!!required.permissionName}
          helperText={required.permissionName ? 'required' : ''}
          style={{ marginBottom: '20px', width: '45%' }}
        />
        <TextField
          margin="dense"
          id="description"
          name="description"
          value={permissionDescriptionForm}
          onChange={event => setPermissionDescriptionForm(event.target.value)}
          label="Description"
          type="text"
          variant="outlined"
          style={{ marginBottom: '20px', width: '45%' }}
        />
      </DialogContent>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4px 24px',
        }}
      >
        <h5
          style={{
            marginBottom: '15px',
            marginTop: '0',
            fontWeight: '500',
            fontSize: 'medium',
          }}
        >
          Select the resources:
        </h5>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            borderTop: '1.8px solid #d6d6d6',
            borderLeft: '1.8px solid #d6d6d6',
            borderRight: '1.8px solid #d6d6d6',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          }}
        >
          <Tabs value={choosedTab} onChange={handleChangeTab}>
            {resources?.map((sp: any, index: any) => (
              <Tab key={index} label={sp.resourceName} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {resourcesHTML}
        <h5
          style={{
            marginBottom: '15px',
            marginTop: '20px',
            fontWeight: '500',
            fontSize: 'medium',
          }}
        >
          Current resources:
        </h5>
        <Paper
          elevation={2}
          style={{
            padding: '5px',
            display: 'flex',
            gap: '2%',
            flexWrap: 'wrap',
          }}
        >
          {choosedResources}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit">{permission ? 'Save' : 'Create'}</Button>
      </DialogActions>
    </Box>
  );
};

export default PermissionContent;
