import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useCallback, useEffect, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import { Company, Dashboard } from '../../../pages/CompaniesPage';
import { User } from '../../../pages/UserGroupsPage';
import AddUserBox from '../../addUserBox';
import api from '../../../services/api';
import { Role } from '../../../pages/AccessControlPage/roleTab';

interface props {
  setOpen: any;
  dashboardListForm: Dashboard[];
  companyListForm: Company[];
  rolesListForm: Role[];
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  usergroup?: any;
  handleClose: any;
  countUser: any;
}

const UserGroupContent: React.FC<props> = ({
  dashboardListForm,
  companyListForm,
  rolesListForm,
  setOpen,
  handleAdd = undefined,
  handleSaveEdit = undefined,
  handleCloseOptions = undefined,
  handleClose,
  usergroup = undefined,
  countUser,
}) => {
  const [userGroupNameForm, setUserGroupNameForm] = useState<string>('');
  const [renderValueDashboard, setRenderValueDashboard] = useState<string>('');
  const [renderValueRole, setRenderValueRole] = useState<string>('');
  const [renderValueCompany, setRenderValueCompany] = useState<string>('');
  const [defaultDashboardList, setDefaultDashboardList] = useState<Dashboard[]>(
    [],
  );
  const [defaultCompany, setDefaultCompany] = useState<Company>();
  const [defaultRolesList, setDefaultRolesList] = useState<Role[]>([]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<string[]>([]);
  const [required, setRequired] = useState<any>({});

  const ItemHeight = 48;
  const ItemPaddingTop = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ItemHeight * 4.5 + ItemPaddingTop,
        width: 250,
      },
    },
  };

  const hasRequiredFields = useCallback((userGroupForm: any): boolean => {
    const requiredObj: any = {};
    if (userGroupForm.name === '') {
      requiredObj.usergroupName = true;
    }

    if (userGroupForm.companyId === undefined) {
      requiredObj.companies = true;
    }

    if (userGroupForm.roles.length === 0) {
      requiredObj.roles = true;
    }

    if (Object.keys(requiredObj).length > 0) {
      setRequired(requiredObj);
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formdata = new FormData(event.currentTarget);
      const userGroupForm: any = {
        name: formdata?.get('userGroupName')?.toString() ?? '',
        companyId: defaultCompany?.companyId,
        dashboards: defaultDashboardList.map((dash: any) => {
          return dash.dashboardId;
        }),
        roles: defaultRolesList.map((role: any) => {
          return role.roleId;
        }),
        users: usersList,
      };

      if (!hasRequiredFields(userGroupForm)) {
        setOpen(false);
        if (usergroup) {
          handleCloseOptions();
          handleSaveEdit({
            ...userGroupForm,
            userGroupId: usergroup.userGroupId,
          });
        } else {
          handleAdd(userGroupForm);
          setRenderValueDashboard('');
          setUserGroupNameForm('');
        }

        if (usersList.length > 0) {
          api.post('/users/createMany', { usersToAdd: usersList });
        }
        if (usersToDelete.length > 0) {
          usersToDelete.forEach((userEmail: string) => {
            const nUser = countUser(userEmail);
            if (nUser === 0) {
              api.delete(`/users/${userEmail}`);
            }
          });
        }
      }
    },
    [
      countUser,
      defaultCompany?.companyId,
      defaultDashboardList,
      defaultRolesList,
      handleAdd,
      handleCloseOptions,
      handleSaveEdit,
      hasRequiredFields,
      setOpen,
      usergroup,
      usersList,
      usersToDelete,
    ],
  );

  const handleChangeDashboard = (event: SelectChangeEvent<any>): void => {
    const {
      target: { value },
    } = event;
    setRenderValueDashboard(
      value.map((obj: any) => {
        return `${obj.dashboardName},`;
      }),
    );
    setDefaultDashboardList(value);
  };

  const handleChangeCompany = (event: SelectChangeEvent<any>): void => {
    const {
      target: { value },
    } = event;
    setRenderValueCompany(value.name);
    setDefaultCompany(value);
  };

  const handleChangeRoles = (event: SelectChangeEvent<any>): void => {
    const {
      target: { value },
    } = event;
    setRenderValueRole(
      value.map((obj: any) => {
        return `${obj.name},`;
      }),
    );
    setDefaultRolesList(value);
  };

  const handleCancel = (): void => {
    setUserGroupNameForm('');
    setDefaultDashboardList([]);
    setRenderValueDashboard('');
    setRenderValueRole('');
    setOpen(false);
    if (usergroup) {
      handleCloseOptions();
    }
    handleClose();
  };

  useEffect(() => {
    if (usergroup) {
      setUserGroupNameForm(usergroup.name);

      const objCompany = companyListForm.find(
        (company: Company) => company.companyId === usergroup.companyId,
      );

      setDefaultCompany(objCompany);

      setRenderValueCompany(objCompany ? objCompany.name : '');

      const inputLabel = dashboardListForm.filter((defaultDash: any) =>
        usergroup.dashboards.includes(defaultDash.dashboardId),
      );

      setDefaultDashboardList(inputLabel);

      setRenderValueDashboard(
        inputLabel
          .map((obj: any) => {
            return `${obj.dashboardName}, `;
          })
          .join(''),
      );

      const rolesList = rolesListForm.filter((role: any) =>
        usergroup.roles.includes(role.roleId),
      );

      setDefaultRolesList(rolesList);

      setRenderValueRole(
        rolesList
          .map((role: any) => {
            return `${role.name}, `;
          })
          .join(''),
      );

      setUsersList(usergroup.users);
    }
  }, [companyListForm, dashboardListForm, rolesListForm, usergroup]);

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} style={{ width: '700px' }}>
        <DialogTitle>
          {usergroup ? 'Edit User Group' : 'Add a new User Group'}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            margin="dense"
            id="userGroupName"
            name="userGroupName"
            value={userGroupNameForm}
            onChange={event => setUserGroupNameForm(event.target.value)}
            label="User Group Name"
            type="text"
            variant="outlined"
            error={!!required.usergroupName}
            helperText={required.usergroupName ? 'required' : ''}
            style={{ marginBottom: '20px' }}
          />

          <FormControl
            style={{ marginBottom: '20px' }}
            error={!!required.companies}
          >
            <InputLabel id="demo-multiple-checkbox-label">Companies</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="companyList"
              label="Company"
              disabled={!!usergroup}
              value={defaultCompany || ''}
              onChange={handleChangeCompany}
              renderValue={() => renderValueCompany}
              input={<OutlinedInput label="Companies" />}
            >
              {companyListForm?.map((company: any) => (
                <MenuItem key={company.companyId} value={company}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {required.companies ? 'required' : ''}
            </FormHelperText>
          </FormControl>

          <FormControl
            style={
              !(defaultCompany?.dashboards.length > 0)
                ? { marginBottom: '20px', display: 'none' }
                : { marginBottom: '20px' }
            }
            error={!!required.dashboards}
          >
            <InputLabel id="demo-multiple-checkbox-label">
              Dashboards
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="dashboardList"
              multiple
              value={defaultDashboardList}
              onChange={handleChangeDashboard}
              renderValue={() => renderValueDashboard}
              input={<OutlinedInput label="Dashboards" />}
              MenuProps={MenuProps}
            >
              {dashboardListForm
                ?.filter(x =>
                  defaultCompany?.dashboards.length > 0
                    ? defaultCompany?.dashboards.includes(x.dashboardId)
                    : x,
                )
                .map((dash: any) => (
                  <MenuItem key={dash.dashboardId} value={dash}>
                    <Checkbox
                      checked={defaultDashboardList.indexOf(dash) > -1}
                    />
                    <ListItemText primary={dash.dashboardName} />
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {required.dashboards ? 'required' : ''}
            </FormHelperText>
          </FormControl>

          <FormControl
            style={{ marginBottom: '20px' }}
            error={!!required.roles}
          >
            <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="rolesList"
              multiple
              value={defaultRolesList}
              onChange={handleChangeRoles}
              input={<OutlinedInput label="Roles" />}
              renderValue={() => renderValueRole}
              MenuProps={MenuProps}
            >
              {rolesListForm?.map((role: any) => (
                <MenuItem key={role.roleId} value={role}>
                  <Checkbox checked={defaultRolesList.indexOf(role) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{required.roles ? 'required' : ''}</FormHelperText>
          </FormControl>
          <AddUserBox
            usersList={usersList}
            setUsersList={setUsersList}
            usersToDelete={usersToDelete}
            setUsersToDelete={setUsersToDelete}
            isEdit={!!usergroup}
          ></AddUserBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit">{usergroup ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Box>
    </>
  );
};

export default UserGroupContent;
