import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../modal';
import AlertDialogSlide from '../../alertDialog';
import { Resource } from '../../../pages/AccessControlPage/resourcesTab';

interface props {
  resource: Resource;
  handleDelete: any;
  handleSaveEdit: any;
}

const ResourceCard: React.FC<props> = ({
  resource,
  handleDelete,
  handleSaveEdit,
}) => {
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const handleCloseOptions = (): void => {
    setAnchorOptions(null);
  };

  const handleClickDelete = (): void => {
    setOpenAlertDialog(true);
  };

  const handleDeletion = (): void => {
    handleDelete({ resource, handleCloseOptions });
    setOpenAlertDialog(false);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const openModalEdit = (): void => {
    setOpen(true);
  };

  return (
    <>
      {openAlertDialog && (
        <AlertDialogSlide
          title={'Delete Resource'}
          msg={'Are you sure you want to delete this Resource?'}
          handleYes={handleDeletion}
          setShow={setOpenAlertDialog}
        />
      )}

      <Card
        sx={{
          maxWidth: 300,
          background: '#ECF3FB',
          marginRight: '30px',
          width: 300,
          borderRadius: '20px',
        }}
      >
        <CardHeader
          title={resource.resourceName}
          style={{ paddingBottom: '5px' }}
          action={
            <>
              <IconButton onClick={handleOptions} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
              <Menu
                sx={{ mt: '35px', left: '10px' }}
                id="menu-appbar"
                anchorEl={anchorOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorOptions)}
                onClose={handleCloseOptions}
              >
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalEdit}
                >
                  <EditIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={() => handleClickDelete()}
                >
                  <DeleteForeverIcon style={{ top: '10px' }} />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '2px',
                    }}
                    textAlign="center"
                  >
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          }
        />

        <CardActions style={{ paddingLeft: '26px', fontSize: 'small' }}>
          list routes here
        </CardActions>
      </Card>
      <Modal
        content={'resource'}
        resourceProp={{
          open,
          setOpen,
          handleSaveEdit,
          resourceEdit: resource,
          handleCloseOptions,
        }}
      ></Modal>
    </>
  );
};

export default ResourceCard;
