import styled from 'styled-components';

export const SegmentButton = styled.button<{ $active: boolean }>`
  flex: 1;
  padding: 8px 10px;
  background: ${props => (props.$active ? '#4c66af' : 'transparent')};
  color: ${props => (props.$active ? '#fff' : '#333')};
  border: none;
  cursor: pointer;

  text-align: center;

  &:hover {
    background: ${props => (props.$active ? '#445a99' : '#e6e6e6')};
  }

  &:not(:last-child) {
    border-right: 1px solid #ccc;
  }

  &:focus {
    outline: none;
  }
`;

export const SegmentedControl = styled.div`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  background: #f9f9f9;
  margin-bottom: 5px;
`;
