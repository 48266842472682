import React, { useState } from 'react';
import { Container, Graph, Grid, GridContainer } from './styles';
import AssingToQuadrant from './quadrants/assingto';
import ScheduleQuadrant from './quadrants/schedule';
import DoitQuadrant from './quadrants/doit';
import DeleteQuadrant from './quadrants/delete';
import TaskBar from '../TodoList/taskBar';
import EffortAxis from './effortAxis';
import ImpactAxis from './impactAxis';
import { Task } from '../../pages/TodoPage';

interface Props {
  tasksPerQuadrant: any;
  taskList: Task[];
  setTaskList: Function;
}

const EisenhowerMatrix: React.FC<Props> = ({
  tasksPerQuadrant,
  taskList,
  setTaskList,
}) => {
  const [taskToEdit, setTaskToEdit] = useState<Task | undefined>();

  return (
    <Container>
      <EffortAxis name="low-effort" />
      <Graph id="graph">
        <ImpactAxis name="low-impact" />
        <GridContainer id="grid-container">
          <Grid id="grid">
            <AssingToQuadrant
              data={tasksPerQuadrant.delegate}
              setTask={setTaskToEdit}
            />
            <DoitQuadrant
              data={tasksPerQuadrant.doit}
              setTask={setTaskToEdit}
            />
            <DeleteQuadrant
              data={tasksPerQuadrant.delete}
              setTask={setTaskToEdit}
            />
            <ScheduleQuadrant
              data={tasksPerQuadrant.schedule}
              setTask={setTaskToEdit}
            />
          </Grid>
        </GridContainer>
        <ImpactAxis name="high-impact" />
      </Graph>
      <EffortAxis name="high-effort" />

      <TaskBar
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        taskList={taskList}
        setTaskList={setTaskList}
      ></TaskBar>
    </Container>
  );
};

export default EisenhowerMatrix;
