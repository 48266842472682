import * as React from 'react';

import { useCallback, useEffect, useState } from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../../components/modal';
import api from '../../../services/api';
import { useLogger } from '../../../context/logContext';
import RoleCard from '../../../components/cards/rolesCard';
import {
  AddButton,
  Cards,
  CustomTextField,
  OptionsBar,
  SearchBar,
  TabPanel,
} from './styles';
import { PermissionResource } from '../permissionTab';

export interface Permission {
  permissionId: number;
  name: string;
  description: string;
  resources: PermissionResource[];
}

export interface Role {
  roleId: number;
  name: string;
  description: string;
  permissions: Permission[];
}

interface Props {
  choosedTab: number;
}

const RoleTab: React.FC<Props> = ({ choosedTab }) => {
  const { logger } = useLogger();
  const [open, setOpen] = useState(false);
  const [rolesList, setRolesList] = useState<Role[]>([]);
  const [permissionsList, setPermissionsList] = useState<Permission[]>([]);
  const [rolesPanel, setRolesPanel] = useState<any>([]);
  const [filteredRoles, setFilteredRoles] = useState<Role[]>([]);

  const handleSubmitOptions = (): void => {
    setOpen(true);
  };

  const handleFilterRoles = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValeuTam = event.target.value.length;
      const filteredRolesAux = rolesList.filter((role: Role) => {
        const nFirstCharacters = role.name.slice(0, inputValeuTam);
        return (
          nFirstCharacters.toLowerCase() === event.target.value.toLowerCase()
        );
      });
      setFilteredRoles(filteredRolesAux);
    },
    [rolesList],
  );

  const getRoles = useCallback(() => {
    api
      .get('/roles')
      .then((response: any) => {
        setRolesList(response.data);
        setFilteredRoles(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const getPermissions = useCallback(() => {
    api
      .get('/permissions')
      .then((response: any) => {
        setPermissionsList(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getRoles();
    getPermissions();
  }, [getPermissions, getRoles]);

  const handleAdd = useCallback(
    (newRole: Role) => {
      api
        .post('/roles', newRole)
        .then(() => {
          getRoles();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'create new role',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getRoles, logger],
  );

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/roles/${obj.role.roleId}`)
        .then(() => {
          getRoles();
        })
        .catch(error => {
          console.log('err', error);
          logger('error', 'Delete role', `${error.code} - ${error.message}`);
        });

      obj.handleCloseOptions();

      const index = rolesList.indexOf(obj.role);
      rolesList.splice(index, 1);
      setRolesList([...rolesList]);
    },
    [getRoles, logger, rolesList],
  );

  const handleSaveEdit = useCallback(
    (roleToEdit: any) => {
      api
        .put(`/roles/${roleToEdit.roleId}`, roleToEdit)
        .then(() => {
          getRoles();
        })
        .catch(error => {
          console.log('err', error);
          logger('error', 'Edit roles', `${error.code} - ${error.message}`);
        });
    },
    [getRoles, logger],
  );

  useEffect(() => {
    setRolesPanel(
      filteredRoles.map((role: Role, index: number) => {
        return (
          <div key={index} style={{ marginTop: '25px' }}>
            <RoleCard
              key={index}
              role={role}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
              permissions={permissionsList}
            ></RoleCard>
          </div>
        );
      }),
    );
  }, [filteredRoles, handleDelete, handleSaveEdit, permissionsList]);

  return (
    <TabPanel id={`tab-panel-0`} hidden={choosedTab !== 0}>
      <OptionsBar id="options">
        <SearchBar id="searchBar">
          <CustomTextField
            id="outlined-basic"
            label="Search..."
            onChange={handleFilterRoles}
            variant="outlined"
          />
        </SearchBar>

        <AddButton id="buttonAdd">
          <Fab color="primary" aria-label="add" onClick={handleSubmitOptions}>
            <AddIcon />
          </Fab>
        </AddButton>
      </OptionsBar>

      <Cards id="cards">{rolesPanel}</Cards>

      <Modal
        content={'role'}
        roleProp={{
          open,
          setOpen,
          permissions: permissionsList,
          handleAdd,
        }}
      ></Modal>
    </TabPanel>
  );
};

export default RoleTab;
