import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Modal from '../../modal';
import AlertDialogSlide from '../../alertDialog';
import { Permission, Role } from '../../../pages/AccessControlPage/roleTab';

interface props {
  role: Role;
  handleDelete: any;
  handleSaveEdit: any;
  permissions: Permission[];
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((expandProps: ExpandMoreProps) => {
  const { ...other } = expandProps;
  const { expand } = expandProps;
  const iconButtonProps = { ...other, expand: expand.toString() };
  return <IconButton {...iconButtonProps} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const RoleCard: React.FC<props> = ({
  role,
  handleDelete,
  handleSaveEdit,
  permissions,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [anchorOptions, setAnchorOptions] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const handleCloseOptions = (): void => {
    setAnchorOptions(null);
  };

  const handleClickDelete = (): void => {
    setOpenAlertDialog(true);
  };

  const handleDeletion = (): void => {
    handleDelete({ role, handleCloseOptions });
    setOpenAlertDialog(false);
  };

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const handleOptions = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorOptions(event.currentTarget);
  };

  const openModalEdit = (): void => {
    setOpen(true);
  };

  return (
    <>
      {openAlertDialog && (
        <AlertDialogSlide
          title={'Delete Role'}
          msg={'Are you sure you want to delete this Role?'}
          handleYes={handleDeletion}
          setShow={setOpenAlertDialog}
        />
      )}

      <Card
        sx={{
          maxWidth: 300,
          background: '#ECF3FB',
          marginRight: '30px',
          width: 300,
          borderRadius: '20px',
        }}
      >
        <CardHeader
          title={role.name}
          style={{ paddingBottom: '5px' }}
          action={
            <>
              <IconButton onClick={handleOptions} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
              <Menu
                sx={{ mt: '35px', left: '10px' }}
                id="menu-appbar"
                anchorEl={anchorOptions}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorOptions)}
                onClose={handleCloseOptions}
              >
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={openModalEdit}
                >
                  <EditIcon />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '1px',
                    }}
                    textAlign="center"
                  >
                    Edit
                  </Typography>
                </MenuItem>
                <MenuItem
                  style={{ paddingLeft: '8px' }}
                  onClick={() => handleClickDelete()}
                >
                  <DeleteForeverIcon style={{ top: '10px' }} />
                  <Typography
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '2px',
                    }}
                    textAlign="center"
                  >
                    Delete
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          }
        />

        <CardActions style={{ paddingLeft: '26px', fontSize: 'small' }}>
          {role.description}
        </CardActions>

        <CardActions disableSpacing>
          {!expanded && <CardContent> Permission list</CardContent>}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {role.permissions.map((permission: any, index: number) => {
              return <li key={index}>{permission.name}</li>;
            })}
          </CardContent>
        </Collapse>
      </Card>
      <Modal
        content={'role'}
        roleProp={{
          open,
          setOpen,
          permissions,
          handleSaveEdit,
          roleEdit: role,
          handleCloseOptions,
        }}
      ></Modal>
    </>
  );
};

export default RoleCard;
