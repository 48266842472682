import * as React from 'react';
import ReactDOM from 'react-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Container } from './styles';
import TaskListTab from './taskListTab';
import EisenhowerTab from './eisenhowerTab';
import api from '../../services/api';
import { GuestUser } from '../../components/guestsBox';

export interface Guest {
  name: string;
  email: string;
}

export type Status = 'wait' | 'in progress' | 'done';

const drawerWidth = 150;

const portalstyles: any = {
  height: '100vh',
  width: '150px',
  backgroundColor: 'rgb(223, 223, 223)',
  position: 'fixed',
  display: { xs: 'none', lg: 'block' },
};

export interface Task {
  id: any;
  _id?: string;
  taskId: any;
  usergroupIds: number[];
  shortTitle: string;
  description: string;
  effort: string;
  impact: string;
  assign: Guest;
  due: Date;
  goal: string;
  status: Status;
  guests: GuestUser[];
  companyId: number;
}

const TodoPage: React.FC = () => {
  const [chosenTab, setChosenTab] = useState<number>(0);
  const [tasks, setTasks] = useState<Task[]>([]);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const getAllTasks = useCallback(() => {
    api
      .get('/tasks/getAll')
      .then(response => {
        if (response.status === 200) {
          setTasks(response.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  /// GETTASK
  useEffect(() => {
    getAllTasks();
  }, [getAllTasks]);

  const handleDrawerClose = (): void => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = (): void => {
    setIsClosing(false);
  };

  const handleDrawerToggle = (): void => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={(): void => setChosenTab(0)}>
            <ListItemText primary={'To-Do´s'} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={(): void => setChosenTab(1)}>
            <ListItemText primary={'Entscheidungs-Matrix'} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const MuiDrawerPaperStyles = {
    backgroundColor: 'rgb(223, 223, 223)',
    boxSizing: 'border-box',
    width: drawerWidth,
    color: 'rgba(0, 0, 0, 0.6)',
    '& li:nth-of-type(1)': {
      color: chosenTab === 0 ? 'rgb(25, 118, 210)' : 'inherit',
      borderRight: chosenTab === 0 ? '3px solid rgb(25, 118, 210)' : 'none',
    },
    '& li:nth-of-type(2)': {
      color: chosenTab === 1 ? 'rgb(25, 118, 210)' : 'inherit',
      borderRight: chosenTab === 1 ? '3px solid rgb(25, 118, 210)' : 'none',
    },
  };

  const tabStyles = {
    width: {
      xs: '100%',
      lg: `calc(100% - ${drawerWidth}px)`,
    },
    left: { xs: `0`, lg: `${drawerWidth}px` },
    position: 'relative',
  };

  const [backgroundFillerDiv, setBackgroundFillerDiv] =
    React.useState<React.ReactPortal | null>(null);
  useEffect(() => {
    // Check for the target element and update state when available
    const checkElement = (): void => {
      const element = document.getElementById('portal-before-root');
      if (element) {
        setBackgroundFillerDiv(
          ReactDOM.createPortal(
            <Box id="backgroundFillerDiv" sx={portalstyles} />,
            element,
          ),
        );
      } else {
        setTimeout(checkElement, 100);
      }
    };
    checkElement();
  }, []);

  return (
    <Container id="container">
      {backgroundFillerDiv}
      <IconButton
        color="inherit"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { lg: 'none' },
          position: 'fixed',
          top: '100px',
          left: '8px',
          zIndex: '1',
          backgroundColor: 'rgb(25, 118, 210)',
          color: 'white',
          borderRadius: '0 20% 20% 0',
          lineHeight: '20px',
        }}
      >
        {'>'}
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            ...MuiDrawerPaperStyles,
          },
        }}
      >
        {drawer}
      </Drawer>

      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', lg: 'block' },
          '& .MuiDrawer-paper': {
            ...MuiDrawerPaperStyles,
            top: '65px',
            height: 'calc(100vh - 63px)',
          },
        }}
        open
      >
        {drawer}
      </Drawer>

      <Box sx={tabStyles} id="todo-list">
        <TaskListTab
          taskList={tasks}
          setTaskList={setTasks}
          chosenTab={chosenTab}
        ></TaskListTab>
      </Box>
      <Box id="decision-matrix">
        <EisenhowerTab
          taskList={tasks}
          setTaskList={setTasks}
          chosenTab={chosenTab}
        ></EisenhowerTab>
      </Box>
    </Container>
  );
};

export default TodoPage;
