/* eslint-disable no-shadow */
enum EnumPermissions {
  CAN_ACCESS_HOME = 'CAN_ACCESS_HOME',
  CAN_ACCESS_USERS = 'CAN_ACCESS_USERS',
  CAN_ACCESS_LOGS = 'CAN_ACCESS_LOGS',
  CAN_ACCESS_DASHBOARDS = 'CAN_ACCESS_DASHBOARDS',
  CAN_ACCESS_COMPANIES = 'CAN_ACCESS_COMPANIES',
  CAN_ACCESS_USERGROUPS = 'CAN_ACCESS_USERGROUPS',
  CAN_ACCESS_CHART = 'CAN_ACCESS_CHART',
  CAN_ACCESS_TASK_LIST = 'CAN_ACCESS_TASK_LIST',
  CAN_ACCESS_ROLES_AND_PERMISSIONS = 'CAN_ACCESS_ROLES_AND_PERMISSIONS',
  NO_ROLE = 'NO_ROLE',
}

export default EnumPermissions;
