import styled, { css } from 'styled-components';

interface ContainerProps {
  isFlipped: boolean;
}

interface FrontCardProps {
  name: string;
}

const getColorByName = (name: string): string => {
  switch (name) {
    case 'ZU ERLEDIGEN':
      return 'rgba(46, 204, 113, 0.4)';
    case 'LÖSCHEN':
      return 'rgba(231, 76, 60, 0.4)';
    case 'ZUWEISEN AN':
      return 'rgba(241, 196, 15, 0.4)';
    case 'PLANEN':
      return 'rgba(0, 123, 255, 0.4)';
    default:
      return 'gray';
  }
};

const getBorder = (name: string): string => {
  switch (name) {
    case 'ZU ERLEDIGEN':
      return 'border-left: 1px solid white; border-bottom: 1px solid white';
    case 'LÖSCHEN':
      return 'border-top: 1px solid white; border-right: 1px solid white;';
    case 'ZUWEISEN AN':
      return 'border-bottom: 1px solid white; border-right: 1px solid white;';
    case 'PLANEN':
      return 'border-left: 1px solid white; border-top: 1px solid white;';
    default:
      return 'border: 1px solid white;';
  }
};

export const QuadrantBox = styled.div<FrontCardProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  ${({ name }) => getBorder(name)};
`;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 90%;
  height: 90%;
  text-align: center;
  font-size: 1.2vw;
  border-radius: 20px;

  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(0);
  transition: transform 0.6s ease-in-out;
  cursor: pointer;

  ${props =>
    props.isFlipped &&
    css`
      transform: rotateY(180deg) !important;
    `}
`;

export const CardFrontSide = styled.div<FrontCardProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  gap: 1px;
  grid-template-columns: repeat(2, 1fr);
  border-radius: 10px;
  color: #fff;
  text-align: center;
  flex-direction: column;

  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(80px);
  background: ${({ name }) => getColorByName(name)};
  -webkit-backdrop-filter: blur(80px);
  border: 1px solid rgba(255, 255, 255, 0.25);
`;

export const Title = styled.div`
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconDiv = styled.div`
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 96.5%;
`;

export const Description = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  padding-top: 5px;
`;
export const Text = styled.p`
  word-wrap: break-word;
  margin: 1vw;
  font-size: 1rem;

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 0.8rem;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    font-size: 0.7rem;
  }

  @media (max-width: 500px) {
    font-size: 0.4rem;
  }
`;

export const CardBackSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: grid;
  // gap: 0.114rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50% 50%;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  transform: rotateY(180deg);
`;
