import styled from 'styled-components';

interface Props {
  color: string;
  name: string;
}

// const getStyle = (name: string): string => {
//   if (name === 'TopLeft') {
//     return 'border-top-left-radius: 20px; border-bottom-right-radius: 5px;';
//   }

//   if (name === 'TopRight') {
//     return 'border-top-right-radius: 20px; border-bottom-left-radius: 5px;';
//   }

//   if (name === 'BottomLeft') {
//     return 'border-bottom-left-radius: 20px; border-top-right-radius: 5px;';
//   }

//   if (name === 'BottomRight') {
//     return 'border-bottom-right-radius: 20px; border-top-left-radius: 5px;';
//   }

//   return 'border-radius: 2px';
// };

const getStyle = (name: string): string => {
  if (name === 'TopLeft') {
    return 'border-top-left-radius: 20px;';
  }

  if (name === 'TopRight') {
    return 'border-top-right-radius: 20px;';
  }

  if (name === 'BottomLeft') {
    return 'border-bottom-left-radius: 20px;';
  }

  if (name === 'BottomRight') {
    return 'border-bottom-right-radius: 20px;';
  }

  return 'border-radius: 2px';
};

export const Container = styled.div<Props>`
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  width: 100%;

  background: ${props => props.color};
  color: black;
  ${props => getStyle(props.name)}
`;

export const ListItem = styled.li`
  font-size: 0.9vw;

  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 1.2vw;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    font-size: 1.4vw;
  }

  @media (max-width: 500px) {
    font-size: 1.6vw;
  }
`;
