import React from 'react';
import {
  PathRouteProps as ReactDOMRouteProps,
  Navigate,
} from 'react-router-dom';
import MainLayout from '../components/mainLayout';
import { useAuth } from '../context/authContext';
import AccessLayer from '../components/accessLayer';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  permission: string;
  component: React.ComponentType;
}

const WrapperRoute: React.FC<RouteProps> = ({
  permission,
  isPrivate = false,
  component: Component,
}) => {
  const { user } = useAuth();

  if (isPrivate) {
    if (!user) {
      return <Navigate to={'/login'} />;
    }

    return (
      <AccessLayer permissionPage={permission}>
        <MainLayout>
          <Component />
        </MainLayout>
      </AccessLayer>
    );
  }

  return <Component />;
};

export default WrapperRoute;
