import styled from 'styled-components';
import { Button } from '@mui/material';

export const Title = styled('div')(() => ({
  width: '30%',
  background: '#1976d2',
  color: 'white',
  textAlign: 'center',
  borderRadius: '5px',
  paddingTop: '8px',
}));

export const CloseButton = styled(Button)`
  background: rgb(127, 149, 207) !important;
  border-radius: 50% !important;
  min-width: auto !important;
  width: 20px !important;
  line-height: 20px !important;
`;

export const Header = styled.div`
  width: 94%;
  align-self: center;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
