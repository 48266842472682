import React, { useEffect, useState } from 'react';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Quadrant from '..';

interface Props {
  data: any;
  setTask: any;
}

const DeleteQuadrant: React.FC<Props> = ({ data, setTask }) => {
  const [deleteInfo, setDeleteInfo] = useState<any>({});

  useEffect(() => {
    setDeleteInfo({
      subQuadData: {
        topLeft: data ? data['e(high)i(vLow)'] : [],
        topRight: data ? data['e(high)i(low)'] : [],
        bottomLeft: data ? data['e(vHigh)i(vLow)'] : [],
        bottomRight: data ? data['e(vHigh)i(low)'] : [],
      },
      name: 'LÖSCHEN',
      description:
        'Diese Aufgaben haben die niedrigste Priorität, da sie nur eine begrenzte Auswirkung oder einen geringen Nutzen haben, aber einen hohen Aufwand erfordern. Sie sollten, wenn möglich, heruntergestuft oder gelöscht werden.',
      backQuadrantsColor: 'rgba(251, 166, 157, 0.80)',
      icon: (
        <DeleteForeverRoundedIcon
          sx={{ width: '11%', height: '40%', marginTop: '10%' }}
        />
      ),
      setTask,
    });
  }, [data, setTask]);

  return <Quadrant info={deleteInfo}></Quadrant>;
};

export default DeleteQuadrant;
