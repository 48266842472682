import * as React from 'react';
import { useEffect, useState } from 'react';
import { Company, Dashboard } from '../../pages/CompaniesPage';
import CompanyContent from './contents/company';
import UserGroupContent from './contents/userGroup';
import { UserGroup } from '../../pages/UserGroupsPage';
import SettingsUserGroupContent from './contents/settingsUserGroup';
import InvalidTokenContent from './contents/invalidToken';
import { CardDialog } from './styles';
import PreviewUserGroupContent from './contents/previewUserGroup';
import { Role } from '../../pages/AccessControlPage/roleTab';
import RoleContent from './contents/role';
import PermissionContent from './contents/permission';
import { Permission } from '../../pages/AccessControlPage/permissionTab';
import ResourceContent from './contents/resource';
import { Resource } from '../../pages/AccessControlPage/resourcesTab';

interface CompanyProps {
  open: boolean;
  setOpen: any;
  dashboardListForm: Dashboard[];
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  companyEdit?: Company;
}

interface RoleProps {
  open: boolean;
  setOpen: any;
  permissions: Permission[];
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  roleEdit?: Role;
}

interface ResourceProps {
  open: boolean;
  setOpen: any;
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  resourceEdit?: Resource;
}

interface PermissionProps {
  open: boolean;
  setOpen: any;
  handleCloseOptions?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  permissionEdit?: Permission;
  resourcesList: Resource[];
}

interface UserGroupProps {
  open: boolean;
  setOpen: any;
  dashboardListForm: Dashboard[];
  companyListForm: Company[];
  rolesListForm: Role[];
  handleCloseOptions?: any;
  handleAdd?: any;
  getUserGroups?: any;
  handleSaveEdit?: any;
  handleSaveSettings?: any;
  userGroupEdit?: UserGroup;
  countUser?: any;
}

interface SettingsUserGroupProps {
  open: boolean;
  setOpenSettings: any;
  handleCloseOptions?: any;
  getUserGroups?: any;
  handleAdd?: any;
  handleSaveEdit?: any;
  handleSaveSettings: any;
  countUser?: any;
}

interface InvalidTokenProps {
  open: boolean;
}

interface ModalProps {
  content: string;
  companyProp?: CompanyProps;
  roleProp?: RoleProps;
  resourceProp?: ResourceProps;
  permissionProp?: PermissionProps;
  userGroupProp?: UserGroupProps;
  settingsUserGroupProp?: SettingsUserGroupProps;
  invalidTokenProp?: InvalidTokenProps;
}

const Modal: React.FC<ModalProps> = ({
  content,
  companyProp = undefined,
  resourceProp = undefined,
  roleProp = undefined,
  permissionProp = undefined,
  userGroupProp = undefined,
  invalidTokenProp = undefined,
}) => {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (companyProp) {
      setData(companyProp);
    } else if (userGroupProp) {
      setData(userGroupProp);
    } else if (resourceProp) {
      setData(resourceProp);
    } else if (roleProp) {
      setData(roleProp);
    } else if (permissionProp) {
      setData(permissionProp);
    } else if (invalidTokenProp) {
      setData(invalidTokenProp);
    }
  }, [
    companyProp,
    invalidTokenProp,
    permissionProp,
    resourceProp,
    roleProp,
    userGroupProp,
  ]);

  const handleClose = (): void => {
    if (!invalidTokenProp) {
      data.setOpen(!data.open);
    }
  };

  return (
    <CardDialog open={data.open ? data.open : false} onClose={handleClose}>
      {
        {
          company: (
            <CompanyContent
              company={data.companyEdit}
              setOpen={data.setOpen}
              dashboardListForm={data.dashboardListForm}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              handleClose={handleClose}
            ></CompanyContent>
          ),
          resource: (
            <ResourceContent
              resource={data.resourceEdit}
              setOpen={data.setOpen}
              permissions={data.permissions}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              handleClose={handleClose}
            ></ResourceContent>
          ),
          role: (
            <RoleContent
              role={data.roleEdit}
              setOpen={data.setOpen}
              permissions={data.permissions}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              handleClose={handleClose}
            ></RoleContent>
          ),
          permission: (
            <PermissionContent
              permission={data.permissionEdit}
              setOpen={data.setOpen}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              handleClose={handleClose}
              resourcesList={data.resourcesList}
            ></PermissionContent>
          ),
          usergroups: (
            <UserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              handleClose={handleClose}
              handleAdd={data.handleAdd}
              handleSaveEdit={data.handleSaveEdit}
              handleCloseOptions={data.handleCloseOptions}
              dashboardListForm={data.dashboardListForm}
              companyListForm={data.companyListForm}
              rolesListForm={data.rolesListForm}
              countUser={data.countUser}
            ></UserGroupContent>
          ),
          usergroupSettings: (
            <SettingsUserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              widgetsListForm={data.widgetsListForm}
              handleClose={handleClose}
              handleAdd={data.handleAdd}
              getUserGroups={data.getUserGroups}
              handleSaveEdit={data.handleSaveEdit}
              handleSaveSettings={data.handleSaveSettings}
              handleCloseOptions={data.handleCloseOptions}
              countUser={data.countUser}
            ></SettingsUserGroupContent>
          ),
          usergroupPreview: (
            <PreviewUserGroupContent
              usergroup={data.userGroupEdit}
              setOpen={data.setOpen}
              handleClose={handleClose}
            ></PreviewUserGroupContent>
          ),
          invalidToken: <InvalidTokenContent></InvalidTokenContent>,
        }[content]
      }
    </CardDialog>
  );
};

export default Modal;
