import React from 'react';
import { Container, EffortLabel } from './styles';

interface Props {
  name: string;
}

const EffortAxis: React.FC<Props> = ({ name }) => {
  return (
    <Container id={name} name={name}>
      {name === 'low-effort' ? (
        <EffortLabel>Sehr geringer Aufwand</EffortLabel>
      ) : (
        <EffortLabel>Sehr hoher Aufwand</EffortLabel>
      )}
    </Container>
  );
};

export default EffortAxis;
