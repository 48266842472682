import React, { useEffect, useState } from 'react';

import { Container, MainContent, MenuAppBarDiv } from './styles';
import MenuAppBar from '../menuAppBar';
import { useAuth } from '../../context/authContext';
import backgroundImage from '../../assets/Background-HomePage.png';
import EnumPermissions from '../../utils/Enums/EnumPermissons';

export interface Page {
  name: string;
  route: string;
  permission: string;
}
interface MainProps {
  children: any;
}

const allPages: Page[] = [
  { name: 'Home', route: '/', permission: EnumPermissions.CAN_ACCESS_HOME },
  {
    name: 'Companies',
    route: '/companies',
    permission: EnumPermissions.CAN_ACCESS_COMPANIES,
  },
  {
    name: 'User Groups',
    route: '/usergroups',
    permission: EnumPermissions.CAN_ACCESS_USERGROUPS,
  },
  {
    name: 'Users',
    route: '/users',
    permission: EnumPermissions.CAN_ACCESS_USERS,
  },
  {
    name: 'Logs',
    route: '/logs',
    permission: EnumPermissions.CAN_ACCESS_LOGS,
  },
  {
    name: 'Chart(test)',
    route: '/chart',
    permission: EnumPermissions.CAN_ACCESS_CHART,
  },
  {
    name: 'Access Control',
    route: '/accesscontrol',
    permission: EnumPermissions.CAN_ACCESS_ROLES_AND_PERMISSIONS,
  },
];

const MainLayout: React.FC<MainProps> = ({ children }) => {
  const { user } = useAuth();
  const [userPages, setUserPages] = useState<Page[]>([]);

  useEffect(() => {
    const pagesByUserRole = user.roles[0].permissions
      .map((userPermission: any) => {
        return allPages.filter(
          (page: Page) =>
            page.permission.toLowerCase() === userPermission.name.toLowerCase(),
        )[0];
      })
      .filter((p: any) => p !== undefined);

    setUserPages(pagesByUserRole);
  }, [user, user.roles]);

  return (
    <>
      <MenuAppBarDiv id="menuAppBar">
        <MenuAppBar pages={userPages} />
      </MenuAppBarDiv>
      <Container id="container">
        <MainContent
          id="main-content"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            height: '100%',
            width: '100%',
            backgroundSize: '101% 101%',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '9%',
          }}
        >
          {children}
        </MainContent>
      </Container>
    </>
  );
};

export default MainLayout;
