import styled from 'styled-components';

export const AddButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px dashed #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-top: 2vh;
  margin-bottom: 1vh;
`;

export const TooltipGuest = styled.div`
  position: absolute;
  top: 40px;
  left: -50px;
  width: 14vw;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  z-index: 10;
`;

export const Title = styled.span`
  font-style: bold;
  margin-right: 0.5vw;
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  color: #555;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveIcon = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: black;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const GuestCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #4c66af;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;

export const ModalGuestBackground = styled.div`
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const OverlayModalGuest = styled(ModalGuestBackground)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export const ModalGuestContent = styled.div`
  margin: auto;
  width: 25%;

  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`;
