import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  background-color: #7e7e7e;
`;

export const MenuAppBarDiv = styled.div`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
  position: relative;
`;
