import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Logo = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

export const UserName = styled.span`
  font-size: 0.9rem;
  margin-left: 0.3vw;
`;
