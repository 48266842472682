import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { Button, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const CloseButton = styled(Button)`
  background: rgb(127, 149, 207) !important;
  border-radius: 50% !important;
  min-width: auto !important;
  width: 20px !important;
  line-height: 20px !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cdcdcd;
  min-height: 52px;
  padding: 0px 25px 0px 25px;
`;

export const SegmentedDiv = styled.div`
  margin-top: 10px;
`;

export const BodyForm = styled.div`
  padding: 10px 25px 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Footer = styled.div`
  border-top: 1px solid #cdcdcd;
  padding: 0px 25px 0px 25px;
  display: flex;
  min-height: 52px;
  align-items: center;
  justify-content: center;
`;

export const DateField = styled(DatePicker)`
  width: 100%;
  margin-bottom: 1rem;

  & .MuiInputBase-root {
    overflow: hidden;
    height: 2.5rem;
  }

  & .MuiInputBase-input {
    font-size: small;
  }
`;

export const FormBox = styled(FormControl)`
  margin-top: 2.2vh !important;

  & .MuiInputLabel-root {
    top: -0.8vh;
  }

  & .MuiInputLabel-shrink {
    top: 0;
  }

  & .MuiOutlinedInput-root {
    height: 2.5rem;

    & .MuiSelect-select {
      padding: 8px 12px;
      font-size: small;
    }
  }
`;

export const Field = styled(TextField)`
  width: 100%;
  margin-bottom: 1rem;

  & .MuiInputBase-root {
    height: 2.5rem;
  }

  & .MuiInputBase-input {
    font-size: small;
  }
  & .MuiInputLabel-root {
    top: -6px;
  }
  & .Mui-focused {
    top: 0;
  }
  & .MuiFormLabel-filled {
    top: 0;
  }
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NewTaskContainer = styled.div`
  @media (max-width: 1440px) {
    width: 50vw;
  }
  @media (max-width: 800px) {
    width: 90vw;
  }
  width: 25vw;

  right: -0.6rem;
  bottom: 0;

  z-index: 7;
  position: fixed;
  height: 100vh;
  background-color: white;
  // padding: 0px 20px;
  // margin: 0px;
  overflow-y: auto;

  /* border-top-left-radius: 8px;
  border-bottom-left-radius: 8px; */
`;

export const Overlay = styled.div<{ $visible: boolean }>`
  bottom: 0;
  right: 0;
  display: ${props => (props.$visible ? 'block' : 'none')};
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #3535355c;
  z-index: 5;
`;

export const Input = styled.input`
  width: calc(100% - 22px);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  display: block; /* Para garantir que o label fique acima do campo */
  font-weight: bold; /* Para destacar o label */
  color: #555; /* Cor do texto do label */
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

export const ModalGuestBackground = styled.div`
  z-index: 8;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ModalGuestContent = styled.div`
  margin: auto;
  width: 25%;

  @media (max-width: 1440px) {
    width: 50%;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const StyledSaveButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4c66af;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  // margin-top: 10px;
  // margin-bottom: 100px;

  &:hover {
    background-color: #445a99;
  }
`;

export const StyledHelperText = styled.span`
  color: '#d32f2f';
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.03333em;
`;
