import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const HeaderWilkommen = styled.div`
  margin-top: 18px;
  text-align: center;
  span {
    font-family: 'SegoeUIBoldItalic';
    font-weight: 700;
    color: white;
    font-size: 2rem;
  }
  @media (min-width: 800px) and (max-width: 1200px) {
    font-size: 2rem;
  }

  @media (min-width: 500px) and (max-width: 800px) {
    font-size: 1.6rem;
  }

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const LinkStyled = styled(Link)`
  display: flex;
  cursor: pointer;
  text-decoration: none !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  align-items: center !important;
  font-size: 1rem !important;
`;

export const Cards = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 5%;

  @media (max-width: 700px) {
    margin-left: 10%;
  }
`;
