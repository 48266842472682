import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridAutosizeOptions,
  gridClasses,
  GridColDef,
  GridComparatorFn,
  GridEventListener,
  GridRowSpacingParams,
  gridStringOrNumberComparator,
  useGridApiRef,
} from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete'; // Importando o ícone de lixeira

import { darken, lighten, styled, Theme } from '@mui/material/styles';
import { Container, StyledAddButton } from './styles';
import api from '../../services/api';
// import { useAuth } from '../../context/authContext';
import TaskBar from './taskBar';
import { Guest, Task } from '../../pages/TodoPage';

const autosizeOptions: GridAutosizeOptions = {
  includeHeaders: true,
  includeOutliers: false,
  outliersFactor: 1.5,
  expand: true,
};

const dateOnlyOptions: Intl.DateTimeFormatOptions = {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const getBackgroundColor = (
  color: string,
  theme: Theme,
  coefficient: number,
): object => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles('light', {
    backgroundColor: lighten(color, coefficient),
  }),
});

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--wait': {
    ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.7),
    '&:hover': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.5),
      '&:hover': {
        ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.4),
      },
    },
  },
  '& .super-app-theme--in-progress': {
    ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.7),
    '&:hover': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.5),
      '&:hover': {
        ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.4),
      },
    },
  },
  '& .super-app-theme--done': {
    ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.7),
    '&:hover': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.5),
      '&:hover': {
        ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.4),
      },
    },
  },
  '& .super-app-theme--Rejected': {
    ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.7),
    '&:hover': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.5),
      '&:hover': {
        ...getBackgroundColor('rgb(180, 180, 180)', theme, 0.4),
      },
    },
  },
  '& .MuiDataGrid-cell': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const ratingOrder = ['Very Low', 'Low', 'High', 'Very High'];
const RatingComparator: GridComparatorFn<string> = (v1: string, v2: string) => {
  return ratingOrder.indexOf(v1) - ratingOrder.indexOf(v2);
};

interface Props {
  taskList: Task[];
  setTaskList: Function;
}

const TodoList: React.FC<Props> = ({ taskList, setTaskList }) => {
  const [taskToEdit, setTaskToEdit] = useState<Task | undefined>();

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 4 : 4,
      bottom: params.isLastVisible ? 4 : 4,
    };
  }, []);

  const handleDelete = (taskIdParam: any): void => {
    // eslint-disable-next-line no-alert
    const confirmDelete = window.confirm(
      ` Are you sure you want to delete task ${taskIdParam} ?`,
    );
    if (confirmDelete) {
      api
        .delete(`/tasks/${taskIdParam}`)
        .then(response => {
          if (response.status === 200) {
            setTaskList([...taskList.filter(x => x.taskId !== taskIdParam)]);
          } else {
            console.error(response);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const columns: GridColDef<Task>[] = [
    // { field: 'taskId', headerName: '', width: 90 },
    {
      field: 'shortTitle',
      headerName: 'Kurztitel',
      maxWidth: 200,
      renderHeader: () => <strong>{'Kurztitel '}</strong>,
    },
    {
      field: 'goal',
      headerName: 'Ziel',
      maxWidth: 200,
      renderHeader: () => <strong>{'Ziel '}</strong>,
    },
    {
      field: 'description',
      headerName: 'Thema',
      maxWidth: 200,
      renderHeader: () => <strong>{'Thema '}</strong>,
    },
    {
      field: 'effort',
      headerName: 'Aufwand',
      type: 'string',
      sortComparator: RatingComparator,
      renderHeader: () => <strong>{'Aufwand '}</strong>,
      renderCell: params => {
        const impact = params.value;
        let background;

        if (impact === 'Low') {
          background =
            'linear-gradient(to right, rgb(164 155 173) 25%, #ffffff 45%)';
        } else if (impact === 'Very Low') {
          background =
            'linear-gradient(to right, rgb(164 155 173) 15%, #ffffff 25%)';
        } else if (impact === 'High') {
          background =
            'linear-gradient(to right,  rgb(164 155 173) 50%, #ffffff 70%)';
        } else if (impact === 'Very High') {
          background = 'rgb(164 155 173)';
        }

        return (
          <div
            style={{
              background,
              padding: '5px 10px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '30px',
              minWidth: '64px',
              margin: '10px',
            }}
          >
            {impact}
          </div>
        );
      },
    },
    {
      field: 'impact',
      headerName: 'Impact',
      sortComparator: RatingComparator,
      type: 'string', // see https://mui.com/x/react-data-grid/custom-columns/
      renderHeader: () => <strong>{'Impact '}</strong>,
      renderCell: params => {
        const impact = params.value;
        let background;
        if (impact === 'Low') {
          background =
            'linear-gradient(to right, rgb(164 155 173) 25%, #ffffff 45%)';
        } else if (impact === 'Very Low') {
          background =
            'linear-gradient(to right,  rgb(164 155 173) 15%, #ffffff 25%)';
        } else if (impact === 'High') {
          background =
            'linear-gradient(to right,  rgb(164 155 173) 50%, #ffffff 70%)';
        } else if (impact === 'Very High') {
          background = 'rgb(164 155 173)';
        }

        return (
          <div
            style={{
              background,
              padding: '5px 10px',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '30px',
              minWidth: '64px',
              margin: '10px',
            }}
          >
            {impact}
          </div>
        );
      },
    },
    {
      field: 'assign',
      headerName: 'Verantwortlich',
      sortComparator: (
        v1: Guest,
        v2: Guest,
        cellParams1: any,
        cellParams2: any,
      ) => {
        return gridStringOrNumberComparator(
          v1.name,
          v2.name,
          cellParams1,
          cellParams2,
        );
      },
      renderHeader: () => <strong>{'Verantwortlich '}</strong>,
      valueFormatter: (params: Guest): string => {
        return params?.name;
      },
    },
    {
      field: 'due',
      headerName: 'Fällig',
      type: 'date',
      renderHeader: () => <strong>{'Fällig '}</strong>,
      valueGetter: value => new Date(value),
      valueFormatter: value =>
        `${new Date(value).toLocaleString('de-AT', dateOnlyOptions)}`,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      renderHeader: () => <strong>{'Status '}</strong>,
      renderCell: params => {
        const status = params.value;
        return status === 'done' ? (
          <span
            role="img"
            aria-label="enjoy"
            title="Done"
            style={{ fontSize: '1.3rem' }}
          >
            ✔️
          </span>
        ) : status === 'wait' ? (
          <span
            role="img"
            aria-label="enjoy"
            title="Wait"
            style={{ fontSize: '1.3rem' }}
          >
            🕒
          </span>
        ) : (
          <span
            role="img"
            aria-label="enjoy"
            title="In Progress"
            style={{ fontSize: '1.3rem' }}
          >
            🔄
          </span>
        );
      },
    },
    {
      field: 'delete',
      headerName: 'Löschen',
      sortable: false,
      filterable: false,
      renderHeader: () => <strong>{'Löschen'}</strong>,
      headerAlign: 'center',
      align: 'center',
      // headerName: '',
      width: 90,
      renderCell: params => (
        <DeleteIcon
          style={{ cursor: 'pointer', color: '#b2b2b2' }}
          onClick={(event: any) => {
            event.stopPropagation();
            handleDelete(params.row.taskId);
          }}
        />
      ),
    },
  ];

  const gridStyles = {
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: 'none',
      },
    [`& .${gridClasses.row}`]: {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      borderRadius: '5px',
      // backgroundColor: '#f8d7da9e',
      overflow: 'hidden',
      '&:hover': {
        //  backgroundColor: '#f5f5f5',
      },
    },
    '& .MuiDataGrid-row:hover': {
      // backgroundColor: '#f3b4b99e',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    border: 0, // or border: 'none'
    '& .MuiDataGrid-footer': {
      border: 'none', // Remove footer border if needed
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none', // Remove header border if needed
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'center',
    },
  };

  const apiRef = useGridApiRef();

  const onRowClickEvent: GridEventListener<'rowClick'> = (
    params, // GridRowParams
    // event, // MuiEvent<React.MouseEvent<HTMLElement>>
    // details, // GridCallbackDetails
  ) => {
    setTaskToEdit(params.row);
    if (apiRef?.current) {
      apiRef.current.autosizeColumns(autosizeOptions);
    }
  };

  const resizeGrid = (): void => {
    if (apiRef?.current?.autosizeColumns) {
      apiRef.current.autosizeColumns(autosizeOptions);
    }
  };

  useEffect(() => {
    let outerTimeoutId = null;
    let innerTimeoutId: any = null;

    outerTimeoutId = setTimeout(() => {
      innerTimeoutId = setTimeout(() => {
        if (apiRef?.current?.autosizeColumns) {
          apiRef.current.autosizeColumns(autosizeOptions);
        }
      }, 10);
    }, 50);

    return () => {
      if (outerTimeoutId) clearTimeout(outerTimeoutId);
      if (innerTimeoutId) clearTimeout(innerTimeoutId);
    };
  }, [apiRef]);

  return (
    <Container>
      <TaskBar
        taskToEdit={taskToEdit}
        setTaskToEdit={setTaskToEdit}
        taskList={taskList}
        setTaskList={setTaskList}
      ></TaskBar>
      <StyledAddButton
        onClick={() => {
          setTaskToEdit({
            effort: 'Very Low',
            impact: 'Very Low',
            status: 'wait',
          } as Task);
          if (apiRef?.current) {
            apiRef.current.autosizeColumns(autosizeOptions);
          }
        }}
      >
        +
      </StyledAddButton>
      {taskList && taskList.length > 0 ? (
        <>
          <StyledDataGrid
            onResize={resizeGrid}
            disableColumnResize={true}
            apiRef={apiRef}
            getRowHeight={() => 'auto'}
            isRowSelectable={() => false}
            getRowSpacing={getRowSpacing}
            sx={gridStyles}
            onRowClick={onRowClickEvent}
            autosizeOptions={autosizeOptions}
            rows={taskList.map(x => {
              const y = x;
              // eslint-disable-next-line no-underscore-dangle
              y.id = x._id;
              return y;
            })}
            columns={columns as any}
            hideFooter={true}
            getRowClassName={params =>
              `super-app-theme--${params.row.status.replace(' ', '-')}`
            }
          />
        </>
      ) : (
        <>
          <span></span>
          <DataGrid columns={columns}></DataGrid>
        </>
      )}
    </Container>
  );
};

export default TodoList;
