import * as React from 'react';

import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import RoleTab from './roleTab';
import PermissionTab from './permissionTab';
import ResourcesTab from './resourcesTab';

export interface Permission {
  permissionId: number;
  name: string;
  description: string;
}

export interface Role {
  roleId: number;
  name: string;
  description: string;
  permissions: Permission[];
}

const AccessControlPage: React.FC = () => {
  const [choosedTab, setChoosedTab] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number): any => {
    setChoosedTab(newValue);
  };

  const controlProps = (index: number): any => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  return (
    <Box
      id="tabs-box"
      sx={{
        flexGrow: 1,
        background: 'transparent',
        display: 'flex',
        height: 224,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={choosedTab}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        style={{ width: '10%' }}
        sx={{
          background: '#dfdfdf',
          borderRight: 1,
          borderColor: 'divider',
          width: '10vw',
          paddingTop: '1vw',
        }}
      >
        <Tab label="Roles" {...controlProps(0)} />
        <Tab label="Permissions" {...controlProps(1)} />
        <Tab label="Resources" {...controlProps(2)} />
      </Tabs>

      <RoleTab choosedTab={choosedTab}></RoleTab>
      <PermissionTab choosedTab={choosedTab}></PermissionTab>
      <ResourcesTab choosedTab={choosedTab}></ResourcesTab>
    </Box>
  );
};

export default AccessControlPage;
