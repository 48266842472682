import styled from 'styled-components';
import CardHeader from '@mui/material/CardHeader';

export const Header = styled(CardHeader)({
  '& .MuiCardHeader-content': {
    '& span': {
      fontSize: '1.25rem',
    },
  },
});
