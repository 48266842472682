import styled from 'styled-components';
import TextField from '@mui/material/TextField';

export const TabPanel = styled.div`
  width: 90%;
`;

export const CustomTextField = styled(TextField)({
  width: '50%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white', // Cor da borda normal
    },
    '& input': {
      color: 'white', // Cor da borda normal
    },
    '&:hover fieldset': {
      borderColor: 'white', // Cor ao passar o mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Cor quando focado
    },
  },
  '& .MuiInputLabel-root': { color: 'white' },
  '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
});

export const SearchBar = styled.div`
  width: 100%;
  align-self: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddButton = styled.div`
  align-self: center;
  padding-top: 10px;
`;

export const OptionsBar = styled('div')(() => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  paddingLeft: '24px',
  paddingRight: '24px',
  paddingTop: '15px',
}));

export const Cards = styled('div')(() => ({
  display: 'flex',
  marginTop: '20px',
  flexWrap: 'wrap',
  marginLeft: '24px',
}));
