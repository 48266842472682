import * as React from 'react';

import TodoList from '../../../components/TodoList';
import { Task } from '..';

interface Props {
  chosenTab: number;
  taskList: Task[];
  setTaskList: Function;
}

const TaskListTab: React.FC<Props> = ({ chosenTab, taskList, setTaskList }) => {
  return (
    <div hidden={chosenTab !== 0}>
      <TodoList taskList={taskList} setTaskList={setTaskList}></TodoList>
    </div>
  );
};

export default TaskListTab;
