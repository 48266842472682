import React from 'react';
import { Container, ListItem } from './styles';

interface Props {
  color: string;
  name: string;
  data: any;
  setTaskToEdit: any;
}

const SubQuadrant: React.FC<Props> = ({ color, name, data, setTaskToEdit }) => {
  const handleClick = (event: any, task: any): void => {
    event.stopPropagation();
    setTaskToEdit(task);
  };

  return (
    <>
      <Container color={color} name={name}>
        {data?.map((task: any, index: number) => (
          <div key={index} onClick={(event: any) => handleClick(event, task)}>
            <ListItem key={index} title={task.goal}>
              {task.shortTitle}
            </ListItem>
          </div>
        ))}
      </Container>
    </>
  );
};

export default SubQuadrant;
