import * as React from 'react';

import { useCallback, useEffect, useState } from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../../components/modal';
import api from '../../../services/api';
import { useLogger } from '../../../context/logContext';
import { TabPanel, OptionsBar, SearchBar, AddButton, Cards } from './styles';
import ResourceCard from '../../../components/cards/resourceCard';
import { CustomTextField } from '../roleTab/styles';

export interface Methods {
  GET: string[];
  POST: string[];
  PUT: string[];
  DELETE: string[];
}

export interface Resource {
  resourceId: number;
  resourceName: string;
  methods: Methods;
}

export interface Permission {
  permissionId: number;
  name: string;
  description: string;
  resources: Resource[];
}

interface Props {
  choosedTab: number;
}

const ResourcesTab: React.FC<Props> = ({ choosedTab }) => {
  const { logger } = useLogger();
  const [open, setOpen] = useState(false);
  const [resourcesList, setResourcesList] = useState<Resource[]>([]);
  const [resourcesPanel, setResourcesPanel] = useState<any>([]);
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);

  const handleSubmitOptions = (): void => {
    setOpen(true);
  };

  const handleFilterResources = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValeuTam = event.target.value.length;
      const filteredResourcesAux = resourcesList.filter(
        (resource: Resource) => {
          const nFirstCharacters = resource.resourceName.slice(
            0,
            inputValeuTam,
          );
          return (
            nFirstCharacters.toLowerCase() === event.target.value.toLowerCase()
          );
        },
      );
      setFilteredResources(filteredResourcesAux);
    },
    [resourcesList],
  );

  const getResources = useCallback(() => {
    api
      .get('/resources')
      .then((response: any) => {
        setResourcesList(response.data);
        setFilteredResources(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getResources();
  }, [getResources]);

  const handleAdd = useCallback(
    (newResource: any) => {
      api
        .post('/resources', newResource)
        .then(() => {
          getResources();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'create new resource',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getResources, logger],
  );

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/resources/${obj.resource.resourceId}`)
        .then(() => {
          getResources();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Delete resource',
            `${error.code} - ${error.message}`,
          );
        });

      obj.handleCloseOptions();

      const index = resourcesList.indexOf(obj.resource);
      resourcesList.splice(index, 1);
      setResourcesList([...resourcesList]);
    },
    [getResources, logger, resourcesList],
  );

  const handleSaveEdit = useCallback(
    (resourceToEdit: any) => {
      api
        .put(`/resources/${resourceToEdit.resourceId}`, resourceToEdit)
        .then(() => {
          getResources();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Edit permission',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getResources, logger],
  );

  useEffect(() => {
    setResourcesPanel(
      filteredResources.map((resource: Resource, index: number) => {
        return (
          <div key={index} style={{ marginTop: '25px' }}>
            <ResourceCard
              key={index}
              resource={resource}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
            ></ResourceCard>
          </div>
        );
      }),
    );
  }, [filteredResources, handleDelete, handleSaveEdit, resourcesList]);

  return (
    <TabPanel id={`tab-panel-2`} hidden={choosedTab !== 2}>
      <OptionsBar id="options">
        <SearchBar id="searchBar">
          <CustomTextField
            id="outlined-basic"
            label="Search..."
            onChange={handleFilterResources}
            variant="outlined"
            style={{ width: '50%' }}
          />
        </SearchBar>

        <AddButton id="buttonAdd">
          <Fab color="primary" aria-label="add" onClick={handleSubmitOptions}>
            <AddIcon />
          </Fab>
        </AddButton>
      </OptionsBar>

      <Cards id="cards">{resourcesPanel}</Cards>

      <Modal
        content={'resource'}
        resourceProp={{
          open,
          setOpen,
          handleAdd,
        }}
      ></Modal>
    </TabPanel>
  );
};

export default ResourcesTab;
