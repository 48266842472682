export const fixedWidgets = [
  {
    widgetName: 'Dashboards',
    widgetUrl: '/dashboards',
    type: 'internal',
  },
  {
    widgetName: 'TODO List',
    widgetUrl: '/tasks',
    type: 'internal',
  },
  {
    widgetName: 'Charts',
    widgetUrl: '/charts',
    type: 'internal',
  },
];
