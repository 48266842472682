import * as React from 'react';

import { useCallback, useEffect, useState } from 'react';

import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../../components/modal';
import api from '../../../services/api';
import { useLogger } from '../../../context/logContext';
import PermissionCard from '../../../components/cards/permissionsCard';
import { AddButton, Cards, OptionsBar, SearchBar, TabPanel } from './styles';
import { Resource } from '../resourcesTab';
import { CustomTextField } from '../roleTab/styles';

export interface PermissionResource {
  resourceName: number;
  resourceType: string;
  resourceRoute: string;
}

export interface Permission {
  permissionId: number;
  name: string;
  description: string;
  resources: PermissionResource[];
}

export interface Role {
  roleId: number;
  name: string;
  description: string;
  permissions: Permission[];
}

interface Props {
  choosedTab: number;
}

const PermissionTab: React.FC<Props> = ({ choosedTab }) => {
  const { logger } = useLogger();
  const [open, setOpen] = useState(false);
  const [permissionsList, setPermissionsList] = useState<Permission[]>([]);
  const [permissionsPanel, setPermissionsPanel] = useState<any>([]);
  const [resourcesList, setResourcesList] = useState<Resource[]>([]);
  const [filteredPermissions, setFilteredPermissions] = useState<Permission[]>(
    [],
  );

  const handleSubmitOptions = (): void => {
    setOpen(true);
  };

  const handleFilterPermissions = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValeuTam = event.target.value.length;
      const filteredPermissionsAux = permissionsList.filter(
        (perm: Permission) => {
          const nFirstCharacters = perm.name.slice(0, inputValeuTam);
          return (
            nFirstCharacters.toLowerCase() === event.target.value.toLowerCase()
          );
        },
      );
      setFilteredPermissions(filteredPermissionsAux);
    },
    [permissionsList],
  );

  const getPermissions = useCallback(() => {
    api
      .get('/permissions')
      .then((response: any) => {
        setPermissionsList(response.data);
        setFilteredPermissions(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });

    api
      .get('/resources')
      .then((response: any) => {
        setResourcesList(response.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const handleDelete = useCallback(
    (obj: any) => {
      api
        .delete(`/permissions/${obj.permission.permissionId}`)
        .then(() => {
          getPermissions();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Delete permission',
            `${error.code} - ${error.message}`,
          );
        });

      obj.handleCloseOptions();

      const index = permissionsList.indexOf(obj.permission);
      permissionsList.splice(index, 1);
      setPermissionsList([...permissionsList]);
    },
    [getPermissions, logger, permissionsList],
  );

  const handleSaveEdit = useCallback(
    (permissionToEdit: Permission) => {
      console.log(permissionToEdit);
      api
        .put(`/permissions/${permissionToEdit.permissionId}`, permissionToEdit)
        .then(() => {
          getPermissions();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'Edit permission',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getPermissions, logger],
  );

  const handleAdd = useCallback(
    (newPermission: Role) => {
      api
        .post('/permissions', newPermission)
        .then(() => {
          getPermissions();
        })
        .catch(error => {
          console.log('err', error);
          logger(
            'error',
            'create new permission',
            `${error.code} - ${error.message}`,
          );
        });
    },
    [getPermissions, logger],
  );

  useEffect(() => {
    setPermissionsPanel(
      filteredPermissions.map((permission: Permission, index: number) => {
        return (
          <div key={index} style={{ marginTop: '25px' }}>
            <PermissionCard
              key={index}
              permission={permission}
              handleDelete={handleDelete}
              handleSaveEdit={handleSaveEdit}
              resourcesList={resourcesList}
            ></PermissionCard>
          </div>
        );
      }),
    );
  }, [filteredPermissions, handleDelete, handleSaveEdit, resourcesList]);

  return (
    <TabPanel id={`tab-panel-1`} hidden={choosedTab !== 1}>
      <OptionsBar id="options">
        <SearchBar id="searchBar">
          <CustomTextField
            id="outlined-basic"
            label="Search..."
            onChange={handleFilterPermissions}
            variant="outlined"
            style={{ width: '50%' }}
          />
        </SearchBar>

        <AddButton id="buttonAdd">
          <Fab color="primary" aria-label="add" onClick={handleSubmitOptions}>
            <AddIcon />
          </Fab>
        </AddButton>
      </OptionsBar>

      <Cards id="cards">{permissionsPanel}</Cards>

      <Modal
        content={'permission'}
        permissionProp={{
          open,
          setOpen,
          handleAdd,
          resourcesList,
        }}
      ></Modal>
    </TabPanel>
  );
};

export default PermissionTab;
